import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarContext } from "../context/SnackbarContext";
import { ApiContext } from "../context/ApiContext";
import HowToModal from "./HowToModal";
import NotificationEach from "./NotificationEach";
import axios from "axios";
import NotificationModal from "./NotificationModal";

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "1rem",
  },
  bodyStyle: {
    background: "linear-gradient(-45deg,#79AC78,#B0D9B1)",
    backgroundSize: "400% 400%",
    animation: "$gradient 10s ease infinite",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "1rem",
  },
  "@keyframes gradient": {
    "0%": { backgroundPosition: "0% 50%" },
    "50%": { backgroundPosition: "100% 50%" },
    "100%": { backgroundPosition: "0% 50%" },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
}));

const Header = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { profile, getNotification, getAllNotifications, notificationCount, notifications, markNotificationAsRead } = useContext(ApiContext);
  const { newSnack } = useContext(SnackbarContext);
  const [isShowHowToModal, setShowHowToModal] = useState(false);
  const [notiOpen, setNotiOpen] = useState("");
  
  // モーダルを閉じた時に全未読通知を既読にする
  const token = localStorage.getItem("current-token");
  const handleModalClose = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/notifications/mark-all-read/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      // コンテキストの通知一覧更新
      getAllNotifications(0);
    } catch (error) {
      console.error("既読更新エラー:", error);
    }
    setNotiOpen("");
  };

  // モーダルの開閉ハンドラ
  const handleNotiOpen = () => {
    if (notiOpen === "show") {
      handleNotiClose();
    } else {
      setNotiOpen("show");
    }
  };

  const handleNotiClose = () => {
    // 個別のクリック時は既読更新しない（個別更新処理内で実施）
    getNotification();
    setNotiOpen("");
  };

  // 初回通知取得
  useEffect(() => {
    if (profile && profile.id) {
      getNotification();
    }
  }, [profile.id]);

  // 3時間未ログインの場合の HowToModal 表示（参考用）
  const checkAndShowModal = async () => {
    if (!profile.id) {
      const lastVisitedTime = localStorage.getItem("lastVisitedTime");
      const currentTime = Date.now();
      const threeHoursInMillis = 3 * 60 * 60 * 1000;
      if (!lastVisitedTime || currentTime - lastVisitedTime > threeHoursInMillis) {
        setShowHowToModal(true);
        localStorage.setItem("lastVisitedTime", currentTime);
      }
    }
  };
  useEffect(() => {
    if (profile) {
      checkAndShowModal();
    }
  }, [profile]);

  return (
    <div className="nav-header bg-white shadow-xs border-0">
      {!profile.id ? (
        <HowToModal isVisible={isShowHowToModal} handleClose={() => setShowHowToModal(false)} />
      ) : null}

      <div className="nav-top">
        <Link to="/recommend">
          <img src="/assets/images/tatami_logo_green.png" alt="logo" className="card p-0 h30 bg-transparent me-2 ms-2 border-0" />
          <span className="fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">
            {t("Header4")}{" "}
          </span>
          <span className="ms-3 mt-0 fw-600 text-current menu-icon font-xsssss mb-0">
            {t("Header23")}
          </span>
          <span className="ms-2 mt-0 fw-600 text-current font-xssssss mob-menu mb-0">
            {t("Header23")}
          </span>
        </Link>
      </div>

      {profile.id ? (
  <>
  <span className="pointer ms-auto me-1 mt-1 d-flex" onClick={() => setNotiOpen("show")}>
    <i className="feather-bell font-xl text-current"></i>
    {notificationCount > 0 && (
      <div className="d-flex">
        <h4 className="fw-600 font-xssss text-warning">{notificationCount}</h4>
        <i className="feather-plus font-xssss text-warning fw-600"></i>
      </div>
    )}
  </span>
  {notiOpen === "show" && (
    <NotificationModal isVisible={notiOpen === "show"} handleClose={handleModalClose} />
  )}
</>
      ) : (
        <span className={`pointer text-center ms-auto`}>
          <Link to="/login">
            <button className="btn menu-icon p-2 lh-24 ms-1 ls-3 rounded-xl btn-outline-current font-xsssss border-0 fw-700 ls-lg bg-current">
              <h2 className="fw-700 w75 mb-1 mt-1 menu-icon font-xsssss text-white-pink align-items-center">{t("Header5")}</h2>
            </button>
            <button className="btn pt-2 mob-menu pb-2 pe-0 ps-0 lh-24 ms-1 ls-3 rounded-xl btn-outline-current font-xsssss border-0 bg-current fw-700 ls-lg">
              <h2 className="fw-700 w75 mb-1 mt-1 font-xsssss text-white-pink align-items-center">{t("Header5")}</h2>
            </button>
          </Link>
        </span>
      )}

      {profile.img && (
        <div className={classes.avatarContainer}>
          <div>
            <Link to="/userpage" className="p-0 menu-icon">
              <img src={profile.img} alt="user" className="w40 mt-1 rounded-circle" />
            </Link>
          </div>
        </div>
      )}

      <nav className="navigation scroll-bar">
        <div className="container ps-0 pe-0">
          <div className="nav-content">
            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                <span></span>{t("Header7")}
              </div>
              <ul className="mb-1 top-content">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li>
                  <Link to="/home" className="nav-content-bttn open-font">
                    <img src="/assets/images/tatami_logo_green_4.png" alt="logo" className="btn-round-md p-1 bg-white me-3 shadow-sm border-light" />
                    <span>{t("Header8")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/recommend" className="nav-content-bttn open-font">
                    <i className="feather-trending-up btn-round-md bg-orange me-3"></i>
                    <span>{t("Header10")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/recommenduser" className="nav-content-bttn open-font">
                    <i className="feather-users btn-round-md bg-current me-3"></i>
                    <span>{t("Header9")}</span>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
              <div className="nav-caption fw-600 font-xssss text-grey-500">
                <span></span> {t("Header11")}
              </div>
              <ul className="mb-1">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li>
                  <Link to="/howto" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                    <i className="font-sm feather-info me-3 text-grey-500"></i>
                    <span>{t("Header19")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/owningpages" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                    <i className="font-sm feather-book me-3 text-grey-500"></i>
                    <span>{t("Header12")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/adapplication" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                    <i className="font-sm feather-radio me-3 text-grey-500"></i>
                    <span>{t("Header15")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/settings" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                    <i className="font-sm feather-settings me-3 text-grey-500"></i>
                    <span>{t("Header13")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Notations-SCTA" className="nav-content-bttn open-font h-auto pt-2 pb-2">
                    <h2 className="fw-500 font-xssss me-3 text-grey-500 text-decoration-underline pointer">
                      {t("Header18")}
                    </h2>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className="app-header-search">
        <form className="search-form">
          <div className="form-group searchbox mb-0 border-0 p-1">
            <input type="text" className="form-control border-0" placeholder="Search..." />
            <i className="input-icon">
              <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
            </i>
            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
              <i className="ti-close font-xs"></i>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Header;
