import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Lightbox from "react-image-lightbox";
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { ApiContext } from '../context/ApiContext';
import FollowEach from "../components/FollowEach";
import { useTranslation } from 'react-i18next';
import LoginComponent from "../components/LoginComponent";
import RecommenduserEach from "../components/RecommenduserEach";
import RecommendEach from "./RecommendEach";
import copy from '../context/clipboard';
import { SnackbarContext } from "../context/SnackbarContext";

const SpecificUserpageJoiningCommunity = () => {
    const { profile, fetchFollowingUsersWithScroll,newRequestFriend, fetchUserCommunitiesWithScroll, intuser, getFollowCheck, deleteFriendRequest, newUserIntUser   } = useContext(ApiContext);
    const { t } = useTranslation();
    const location = useLocation();

    // クエリパラメータから user_id を取得
    const queryParams = new URLSearchParams(location.search);
    const user_id = queryParams.get('user_id');
    const [isOpenModal, setIsOpenModal] = useState(false);

    const [followingUsers, setFollowingUsers] = useState([]);
    const [count, setCount] = useState(0);
    const loaderRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [noMoreData, setNoMoreData] = useState(false);
    const { newSnack } = useContext(SnackbarContext);

    useEffect(() => {
      if (user_id) {
        newUserIntUser(user_id);
      }
    }, [user_id]);

    const fetchFollowingUsers = async () => {
        setIsLoading(true);
        const data = await fetchUserCommunitiesWithScroll(user_id, count);
        if (data.length === 0) {
            setNoMoreData(true);
        } else {
            setFollowingUsers(prev => [...prev, ...data]);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchFollowingUsers();
    }, [count, user_id]);

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isLoading && !noMoreData) {
            setCount(prev => prev + 1);
        }
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }
        return () => observer.disconnect();
    }, []);

    const listCommunities = followingUsers.map(community => (
      <RecommenduserEach
        key={community.id}
        profileeach={community}
      />
    ));
    const [isFollowed, setIsFollowed] = useState(false); // フォロー状態を保持するstate

    useEffect(() => {
      const checkFollowStatus = async () => {
        if (profile.id) {  // ログインしている場合のみフォロー状態を確認
          const followCheck = await getFollowCheck(user_id);
          setIsFollowed(followCheck.following);
        }
      };
  
      if (user_id) {
        checkFollowStatus();
      }
    }, [user_id, profile.id]);
  
    const createNewRequest = async () => {
      const uploadDataAsk = new FormData();
      uploadDataAsk.append("askTo", user_id);
      uploadDataAsk.append("approved", false);
      await newRequestFriend(uploadDataAsk);
      setIsFollowed(true); // フォロー状態を更新
    };
  
    const handleUnfollow = async () => {
      await deleteFriendRequest(profile.userProfile, user_id);
      setIsFollowed(false); // フォロー解除後の状態を更新
    };
  
    const sharePage = () => {
      copy(window.location.href);
      newSnack("info", t('SpecificUserpageEach1'));
    };
    
    return (
      <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left ps-2 pe-2">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <div className="card w-100 border-0 p-0 pt-10 mt-5 bg-white shadow-xss rounded-xxl">
                  <div className="card-body d-block  pt-1 pb-0 ps-2 pe-2">
                    {/* {profile.imgBackground ? (
                      <div className="row ps-2 pe-2">
                        <div className="col-sm-12 p-1">
                          <img
                            src={profile.imgBackground}
                            className="rounded-xxl w-100"
                            alt="post"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )} */}
                  </div>
                  <div className="card-body p-0 position-relative">
        {intuser.id ? (
          <div>
            {isOpenModal && (
              <Lightbox
                mainSrc={process.env.REACT_APP_API_URL + intuser.img}
                onCloseRequest={() => setIsOpenModal(false)}
              />
            )}
            <div onClick={() => setIsOpenModal(true)}>
              <figure
                className="avatar position-absolute w100 z-index-1"
                style={{ top: "-40px", left: "30px" }}
              >
                <img
                  src={process.env.REACT_APP_API_URL + intuser.img}
                  alt="avater"
                  className="float-right p-1 bg-white rounded-circle w-100"
                />
              </figure>
            </div>
          </div>
        ) : (
          <figure
            className="avatar position-absolute w100 z-index-1"
            style={{ top: "-40px", left: "30px" }}
          >
            <img
              src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
              alt="avater"
              className="float-right p-1 bg-white rounded-circle w-100"
            />
          </figure>
        )}
        {intuser.nickName ? 
          <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
          {intuser.nickName}
          <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
            {intuser.created_on}
          </span>
        </h4>:
        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
        {t('SpecificUserpageEach2')}
        <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
        {t('SpecificUserpageEach3')}
        </span>
        </h4>
        }
        
        <div className="position-absolute bottom-1 mb-0 mt-2 right-15">
          {/* <button
            onClick={sharePage}
            className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
          >
            {t('SpecificUserpageEach4')}
          </button> */}
          {profile.id && (  // ログインしている場合のみフォローボタンを表示
            !isFollowed ? (
              <button
                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                onClick={createNewRequest}
              >
                {t('SpecificUserpageEach5')}
              </button>
            ) : (
              <button
                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white bg-"
                onClick={handleUnfollow}
              >
                {t('SpecificUserpageEach6')}
              </button>
            )
          )}
        </div>
      </div>

                  <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <ul
                      className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to={`/userfollowing?user_id=${user_id}`}
                          data-toggle="tab"
                        >
                          {t('Followed1')}
                        </Link>
                      </li>
                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to={`/userfollowed?user_id=${user_id}`} 
                          data-toggle="tab"
                        >
                          {t('Followed2')}
                        </Link>
                      </li>
                      <li className="active list-inline-item me-5">
                        <Link
                          className="active fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to={`/usercommunitylist?user_id=${user_id}`}
                          data-toggle="tab"
                        >
                          {t('Followed3')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-3">
                {/* <Profiledetail />
                                <UserpagePicture /> */}
              </div>
                <div className="col-xl-12 col-xxl-12 col-lg-12">
                                    <div className="row ps-2 pe-2">
                                        {followingUsers.length > 0 ? (
                                        <div className="row ps-2 pe-2">
                                            {listCommunities}
                                        </div>
                                        ) : (
                                        !isLoading && <p></p>
                                        )}
                                    </div>
                                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }}></div>
                                {isLoading && (
                                    <div className="card-body p-0 mb-3">
                                        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                            <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                                                <div className="stage">
                                                    <div className="dot-typing"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
    );
};

export default SpecificUserpageJoiningCommunity;
