import React, { useReducer, Fragment, useContext, useState, useEffect } from "react";
import { withCookies } from "react-cookie";
import axios from "axios";
import { Link, useHistory, useParams  } from 'react-router-dom';
import { ApiContext } from '../context/ApiContext';
import { useGoogleLogin } from '@react-oauth/google';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";

import {
	START_FETCH,
	FETCH_SUCCESS,
	ERROR_CATCHED,
	//INPUT_EDIT,
	INPUT_EDIT_LOG,
	INPUT_EDIT_REG,
	TOGGLE_MODE,
} from "../components/actionTypes"
import { SnackbarContext } from "../context/SnackbarContext";

const initialState = {
	isLoading: false,
	isLoginView: true,
	error: "",
	credentialsLog: {
		username: "",
		password: "",
	},
	credentialsReg: {
		email: "",
		password: "",
	},
};

const useStyles = makeStyles((theme) => {
	return {

		line: {
			display: 'block',
			position: 'relative',
			'&::after': {
			  background: 'linear-gradient(to right,#79ac78 0%,#79ac78 50%,#79ac78 100%)',
			  content: '""',
			  display: 'block',
			  height: '2px',
			  width: 0,
			  transform: 'translateX(-50%)',
			  transition: '2s cubic-bezier(0.22, 1, 0.36, 1)',
			  position: 'absolute',
			  bottom: 0,
			  left: '50%',
			},
			'&.is-animated::after': {
			  width: '100%',
			},
		  },
		}})

const loginReducer = (state, action) => {
	switch (action.type) {
		case START_FETCH: {
			return {
				...state,
				isLoading: true,
			};
		}
		case FETCH_SUCCESS: {
			return {
				...state,
				isLoading: false,
			};
		}
		case ERROR_CATCHED: {
			return {
				...state,
				error: action.payload ? action.payload : "Email or password is not correct!",
				isLoading: false,
			};
		}
		case INPUT_EDIT_LOG: {
			return {
				...state,
				credentialsLog: {
					...state.credentialsLog,
					[action.inputName]: action.payload,
				},
				error: "",
			};
		}
		case INPUT_EDIT_REG: {
			return {
				...state,
				credentialsReg: {
					...state.credentialsReg,
					[action.inputName]: action.payload,
				},
				error: "",
			};
		}
		case TOGGLE_MODE: {
			return {
				...state,
				isLoginView: !state.isLoginView,
			};
		}
		default:
			return state;
	}
};

const CommunityLogin = (props) => {
    const history = useHistory();
	const [user, setUser] = useState(null);
	const { newSnack } = useContext(SnackbarContext);
	const [gProfile, setGProfile] = useState(null);
	const [state, dispatch] = useReducer(loginReducer, initialState);
	const { profile } = useContext(ApiContext)
	const classes = useStyles();
	const [animate, setAnimate] = useState(false);
	const { communityid } = useParams();
	// コンポーネントがマウントされた時に実行される
	useEffect(() => {
	  setAnimate(true);
	}, []); // 空の依存配列でコンポーネントのマウント時のみ実行

	const params = new URLSearchParams(window.location.search);
	const invitedCommunityId = params.get("community");
	const [communityData, setCommunityData] = useState(null);

	useEffect(() => {
		// コミュニティ情報取得API（適宜URLを変更してください）
		axios.get(`${process.env.REACT_APP_API_URL}/api/user/community/${communityid}/`, {
		  headers: { Authorization: `Token ${localStorage.getItem("current-token")}` }
		})
		.then((res) => {
		  setCommunityData(res.data);
		})
		.catch((err) => console.error(err));
	  }, [communityid]);

	  // ログイン成功後の共通処理：参加申請を自動作成して遷移
// ログイン成功後の共通処理：参加申請を自動作成して遷移
const handleJoinRequestAfterLogin = async (isNewUser) => {
	try {
	  await axios.post(
		`${process.env.REACT_APP_API_URL}/api/user/communityjoin/`,
		{ community: communityid }, // POSTデータに community のID を含める
		{ headers: { Authorization: `Token ${localStorage.getItem("current-token")}` } }
	  );
	  newSnack("success", "サークルへ参加申請しました");
	  if (isNewUser) {
		history.push("/profile-create");
	  } else {
		history.push(`/communitypage/${communityid}`);
	  }
	} catch (error) {
	  const errMsg = error.response?.data?.detail;
	  // (a) 招待済み、(b) 既にメンバー、(c) 既に参加申請済みの場合
	  if (
		errMsg === "このコミュニティに対して既に招待が作成されています。" ||
		errMsg === "すでにこのコミュニティのメンバーです。" ||
		errMsg === "このコミュニティに対して既に参加申請が作成されています。"
	  ) {
		newSnack("error", errMsg);
		if (isNewUser) {
		  history.push("/profile-create");
		} else {
		  history.push(`/communitypage/${communityid}`);
		}
	  } else {
		newSnack("error", "参加申請の送信に失敗しました");
		// すでに登録済みの場合はエラーが発生しても・サークルページに遷移
		if (!isNewUser) {
		  history.push(`/communitypage/${communityid}`);
		}
	  }
	}
  };
  
	  

	const gLogin = useGoogleLogin({
		onSuccess: (codeResponse) => {
			dispatch({ type: START_FETCH });
			setUser(codeResponse);
		},
		onError: (error) => console.log('Login Failed:', error)
	});

	useEffect(() => {
		if (user) {
		  axios
			.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
			  headers: {
				Authorization: `Bearer ${user.access_token}`,
				Accept: "application/json",
			  },
			})
			.then((res) => {
			  if (res.data.verified_email) {
				setGProfile(res.data);
			  } else {
				dispatch({ type: ERROR_CATCHED, payload: t("Login1") });
			  }
			  console.log(res.data);
			})
			.catch((err) => console.log(err));
		}
	  }, [user]);


  useEffect(() => {
    if (gProfile) {
		axios
		  .post(
			`${process.env.REACT_APP_API_URL}/api/user/loginGoogle/`,
			{
			  email: gProfile.email,
			  given_name: gProfile.given_name,
			  family_name: gProfile.family_name,
			  name: gProfile.name,
			  picture: gProfile.picture,
			},
			{ headers: { "Content-Type": "application/json" } }
		  )
		  .then((res) => {
			localStorage.setItem("current-token", res.data.token);
			const newUser = res.data.new_user; // APIから新規ユーザーかどうかのフラグを取得
			handleJoinRequestAfterLogin(newUser);
		  })
		  .catch((err) => {
			console.log(err);
			newSnack("error", t("Login2"));
		  });
	  }
  
  }, [gProfile, profile, props]);


	const { t } = useTranslation();

	return (
		<Fragment>
			<div className="main-wrap">
				<div className="nav-header bg-transparent shadow-none border-0">
					<div className="nav-top w-100">
						<Link to="/recommend">
						<img src="/assets/images/tatami_logo_green.png" alt="logo" className="card p-0 h30 bg-transparent me-2 ms-2 border-0"/>
							<span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">{t('Login4')}</span> </Link>


						{/* <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">ログイン</a>
                        <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">新規登録</a> */}
					</div>
				</div>
				<div className="row">
					<div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
					style={{backgroundImage: 'url("/assets/images/login.jpg")'}}
					></div>
					<div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
						<div className="card shadow-none border-0 ms-auto me-auto login-card">
							<div className="card-body rounded-0 text-left">


									<h2 className="fw-700 text-current display1-size display2-md-size mb-3">Tatamiへ<br />ようこそ</h2>

									{communityData ? 
                                <div className="card border-0 text-center d-block p-0">
                                    <img src={communityData.image.slice(0,4)==="http" ? communityData.image :process.env.REACT_APP_API_URL + communityData.image} alt="icon" className="w-50 rounded-xl card border-white border-1 shadow-md mb-2 ms-auto me-auto p-1" />
                                    {/* <h1 className="fw-700 text-grey-900 display3-size display4-md-size">Oops! It looks like you're lost.</h1> */}
                                    <p className="text-grey-600 font-xssss">「{communityData.title}」へ招待されています</p>
                                </div>
								:
								<div className="card border-0 text-center d-block p-0">
								<img src="https://tatami.site/default_meta_image.jpg" alt="icon" className="w300 mb-4 ms-auto me-auto pt-md-5" />
								{/* <h1 className="fw-700 text-grey-900 display3-size display4-md-size">Oops! It looks like you're lost.</h1> */}
								<p className="text-grey-500 font-xsss">サークルに招待されています</p>
							</div>}


							<div className={`text-current fw-600 text-center mb-1 mt-1 font-xsss `}>Googleアカウントでサークルに参加</div>
							<button className="form-control text-center style2-input text-white-pink fw-600 bg-current border-0 shadow-md mt-2 mb-2 p-0 border-dark-md" type="button" onClick={() => gLogin()}>
								<svg viewBox="0 0 48 48" style={{ height: '18px', marginRight: '8px', minWidth: '18px', width: '18px', position: 'relative', verticalAlign: 'middle' }}>
									<g>
										<path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
										<path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
										<path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
										<path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
										<path fill="none" d="M0 0h48v48H0z"></path>
									</g>
								</svg>
								{t('Login19')}
							</button>
					
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default withCookies(CommunityLogin);