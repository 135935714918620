import React, { useCallback, useState, useEffect } from "react";
import { useContext } from "react";
import { ApiContext } from "../context/ApiContext";
import getCroppedImg from "./getCroppedImg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
// import { Link } from "react-router-dom";
// import { profile } from 'console';
// import { post } from 'jquery';
import Area from "react-easy-crop";
import MediaSize from "react-easy-crop";
import "./styles.css";
import CropperModal from "./CropperModal";
import { useTranslation } from 'react-i18next';
// import LinearProgress from '@mui/joy/LinearProgress'
import {
  Button,
  //  makeStyles,
  Slider,
} from "@material-ui/core";
import { PermPhoneMsg } from "@material-ui/icons";
// import { create } from 'domain';
export const ASPECT_RATIO = 2 / 1;
export const CROP_WIDTH = 400;


const CreateCommunityPost = (props) => {
  const {
    profile,
    // postimg,
    setPostimg,
    createCommunityPost,
    createdcommunitypost,
    setCreatedcommunitypost,
  } = useContext(ApiContext);

  /** Cropモーダルの開閉 */
  const [isOpen, setIsOpen] = useState(false);

  /** アップロードした画像URL */
  const [imgSrc, setImgSrc] = useState("");

  /** 画像の拡大縮小倍率 */
  const [zoom, setZoom] = useState(1);
  /** 画像拡大縮小の最小値 */
  const [minZoom, setMinZoom] = useState(1);

  /** 切り取る領域の情報 */
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  /** 切り取る領域の情報 */
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  /** 切り取ったあとの画像URL */
  const [croppedImgSrc, setCroppedImgSrc] = useState("");

  const [content, setContent] = useState("");

  const doPost = () => {
    onHandleClose();

    const tempPost = createdcommunitypost;
    tempPost.text = content;
    console.log(tempPost)

    setCreatedcommunitypost(tempPost);

    createCommunityPost(props.communityId);

    

    setContent("")
    setCreatedcommunitypost({
      ...createdcommunitypost,
    });
  };


  const onHandleClose = () => {
    setCroppedImgSrc("");
    setPostimg([]);
    props.handleClose();
    setContent("")
    setCreatedcommunitypost({
      ...createdcommunitypost,
    });
  }

  /**
   * ファイルアップロード後
   * 画像ファイルのURLをセットしモーダルを表示する
   */
  const onFileChange = useCallback(async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || "");
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);
  /**
   * Cropper側で画像データ読み込み完了
   * Zoomの最小値をセットしZoomの値も更新
   */
  const onMediaLoaded = useCallback((mediaSize) => {
    const { width, height } = mediaSize;
    const mediaAspectRadio = width / height;
    if (mediaAspectRadio > ASPECT_RATIO) {
      // 縦幅に合わせてZoomを指定
      const result = CROP_WIDTH / ASPECT_RATIO / height;
      setZoom(result);
      setMinZoom(result);
      return;
    }
    // 横幅に合わせてZoomを指定
    const result = CROP_WIDTH / width;
    setZoom(result);
    setMinZoom(result);
  }, []);

  /**
   * 切り取り完了後、切り取り領域の情報をセット
   */
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  /**
   * 切り取り後の画像を生成し画面に表示
   */
  const showCroppedImage = useCallback(async () => {
    if (!croppedAreaPixels) return;
    try {
      const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
      setCroppedImgSrc(croppedImage);
      const file = new File([croppedImage], `profile_view.jpg`, {
        type: croppedImage.type,
      });
      setPostimg(file);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imgSrc, profile.nickName, setPostimg]);

  const handleInputChange = (event) => {
    setContent(event.target.value)
  };


  const handleChange = (event, newValue) => {
    setCreatedcommunitypost({
      ...createdcommunitypost,
    });
  };

  useEffect(() => {
    setCreatedcommunitypost({
      ...createdcommunitypost,
    });
  }, []);

  const { t } = useTranslation();



  return (
    <Dialog
      className="animated  p-0"
      open={props.isVisible}
      onClose={() => onHandleClose()}
      aria-labelledby="max-width-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      {/* <DialogTitle className="font-xsss" id="max-width-dialog-title">意見をポスト</DialogTitle> */}
      <DialogContent className=" card w-000 shadow-xss rounded-xxl border-0 ps-0 pt-0 pe-0 pb-3 mt-1 mb-1">
      <div
              className="position-absolute right-0 top-0 pointer me-3"
              onClick={() => onHandleClose()}
            >
              <i className="ti-close text-grey-400 fw-700 font-xsss"></i>
            </div>


        <form action="#">


            <div className="card-body ps-2 pe-2 pt-4 pb-1 position-relative">
              {
                profile.img ?
                <figure className="avatar position-absolute ms-2 mt-2 top---10">
                <img
                  src={profile.img.slice(0,4)==="http" ? profile.img :process.env.REACT_APP_API_URL + profile.img}
                  alt="icon"
                  className="shadow-sm rounded-circle w30"
                />
              </figure>:
              <figure className="avatar position-absolute ms-2 mt-2 top-5">
              <img
                src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                alt="icon"
                className="shadow-sm rounded-circle w30"
              />
            </figure>
              }

              
              <textarea
                onChange={handleInputChange}
                value={content}
                name="text"
                className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xss fw-500 theme-dark-text border-light-md theme-dark-bg mb-4"
                cols="30"
                rows="10"
                placeholder="サークルに投稿..."
              ></textarea>
            </div>
            <div className="card-body d-block p-0">
              {croppedImgSrc ? (
                <div className="card-body  position-relative">
                  <label className="mont-font fw-600 font-xssss mb-1">
                  {t('Createpost3')}
                  </label>
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* ここからトリミング */}
            <div className="card-body p-0 d-flex ">
              <div className="file-upload-container position-absolute right-15 bottom-5">
                <Button
                  className="bg-current text-center text-white font-xsss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1"
                  variant="contained"
                  component="label"
                >
                  {t('Createpost4')}
                  <input type="file" hidden onChange={onFileChange} />
                </Button>

                {content ? (
                  <Button
                    className="bg-current text-center text-white font-xsss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1"
                    variant="contained"
                    onClick={doPost}
                  >
                    {t('Createpost5')}
                  </Button>
                ) : (
                  <Button
                    onClick={() => onHandleClose()}
                    className="text-center text-white font-xsss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1"
                    variant="contained"
                    disabled
                  >
                    {t('Createpost6')}
                  </Button>
                )}
                {/* <Button onClick={() => onHandleClose()} className="bg-current text-center text-white font-xsss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3">
                  閉じる
                </Button> */}
              </div>
              <CropperModal
                crop={crop}
                setCrop={setCrop}
                zoom={zoom}
                setZoom={setZoom}
                onCropComplete={onCropComplete}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                imgSrc={imgSrc}
                showCroppedImage={showCroppedImage}
                onMediaLoaded={onMediaLoaded}
                minZoom={minZoom}
              />
            </div>
        </form>
      </DialogContent>

    </Dialog>
  );
};

export default CreateCommunityPost;
