import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { ApiContext } from "../context/ApiContext";
import { SnackbarContext } from "../context/SnackbarContext";

const useStyles = makeStyles({
  card: {
    maxWidth: 400,
    marginBottom: "1rem",
    position: "relative",
    maxHeight: 330,
    minHeight: 350,
    overflow: "hidden",
  },
  cardContent: {
    minHeight: "130px",
    maxHeight: "300px",
    overflow: "hidden",
  },
  media: {
    height: 400,
    width: "100%",
    objectFit: "contain", // 画像がはみ出さないように
    display: "block",
    position: "relative",
    zIndex: 2,
  },
  moveToBtn: {
    position: "absolute",
    right: "10px",
    bottom: "20px",
    zIndex: "3",
  },
  // affiliate バナー用のコンテナ：中央揃え＆はみ出し防止
  affiliateContainer: {
    maxHeight: "400px",
    maxWidth: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // affiliate 内の img に対して自動縮小を適用
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      display: "block",
    },
  },
});

const AdvertisementView = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { newSnack } = React.useContext(SnackbarContext);
  const { addAdsCnt } = React.useContext(ApiContext);

  useEffect(() => {
    if (props.item && props.item.type === 0) {
      addAdsCnt(props.item.id);
    }
  }, [props.item, addAdsCnt]);

  const toAdsDetail = () => {
    if (props.item.type === 1) addAdsCnt(props.item.id);
    if (!props.item.url) return;
    window.location = props.item.url;
  };

  return (
    <div>
      {props.item && props.item.userProfile ? (
        <div className="card w-100 shadow-xss rounded-xxl border-light-md ps-3 pe-3 pt-3 pb-2 mb-1">
          <div className="relative">
            {/* ユーザープロフィール部分 */}
            <div className="card-body p-0 d-flex">
              <div>
                <div className="card-body p-0 d-flex">
                  {props.item.userProfile.img ? (
                    <figure className="avatar me-3">
                      <img
                        src={
                          props.item.userProfile.img.slice(0, 4) === "http"
                            ? props.item.userProfile.img
                            : process.env.REACT_APP_API_URL + props.item.userProfile.img
                        }
                        alt="avatar"
                        className="shadow-sm rounded-circle w45"
                      />
                    </figure>
                  ) : null}
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                    {props.item.userProfile.nickName}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.item.created}
                    </span>
                  </h4>
                </div>
                <div className="card-body p-0 me-lg-5">
                  <p className="fw-600 text-grey-600 lh-26 font-xssss w-100 mb-2">
                    {props.item.content}
                  </p>
                </div>
              </div>
            </div>
            {/* 画像（またはaffiliateのバナー）表示部分 */}
            <div className="card shadow-xss rounded-xxl border-light-md pt-2 pb-2 pe-2 ps-2 mb-0">
              <div className="row ps-3 pe-3">
                <div className="col-sm-12 p-1 me-2 ms-0 d-flex justify-content-center">
                  {props.item.is_affiliate && props.item.affiliate_html ? (
                    // affiliateの場合：affiliateContainer でラップして中央揃え＆はみ出し防止
                    <div
                      className={clsx("rounded-3 pointer", classes.affiliateContainer)}
                      onClick={toAdsDetail}
                      dangerouslySetInnerHTML={{ __html: props.item.affiliate_html }}
                    />
                  ) : props.item.img ? (
                    // 通常の場合：画像を表示（objectFit: "contain" で縮小）
                    <img
                      className={classes.media}
                      alt="post"
                      src={
                        props.item.img.slice(0, 4) === "http"
                          ? props.item.img
                          : process.env.REACT_APP_API_URL + props.item.img
                      }
                      onClick={toAdsDetail}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="position-absolute right-0 top-5 me-1">
              <span className="card me-1 text-grey-500 fw-600 text-uppercase font-xssss float-left rounded-xl border-0 d-inline-block mt-3 me-3 lh-20 text-center pointer ls-3 w50 mb-3">
                {t('AdvertisementView1')}
              </span>
            </div>
          </div>
          <div className="card-body d-flex p-0">
            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-600 text-dark lh-26 font-xsssss me-2">
              <i className="feather-radio text-dark text-dark text-grey-600 btn-round-sm font-sm"></i>
              {t('AdvertisementView2')}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AdvertisementView;
