import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../context/ApiContext";
import AttributeEachforModal from "./AttributeEachforModal";

const AttributesModal = (props) => {
  const { getAttributesWithScroll, searchAttributes } = useContext(ApiContext);
  const [showattribute, setShowattribute] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);

  const scrollContainerRef = useRef(null);
  const loaderRef = useRef(null);
  const { t } = useTranslation();

  // count を引数で受け取ることで依存関係から外す
  const fetchMoreAttributes = useCallback(async (currentCount) => {
    setIsLoading(true);
    const result = await getAttributesWithScroll(props.lan, currentCount);
    if (result.length === 0) {
      setNoMoreData(true);
    } else {
      setShowattribute((prev) => [...prev, ...result]);
      // 次のページを取得するために count を更新
      setCount(currentCount + 1);
    }
    setIsLoading(false);
  }, [getAttributesWithScroll, props.lan]);

  // モーダル表示時や言語変更時にデフォルトデータをリセット
  const resetAttributes = useCallback(() => {
    setShowattribute([]);
    setCount(0);
    setNoMoreData(false);
    // 初回データを取得（count は 0 を渡す）
    fetchMoreAttributes(0);
  }, [fetchMoreAttributes]);

  // IntersectionObserver のコールバック
  const handleObserver = useCallback(
    (entities) => {
      const target = entities[0];
      if (target.isIntersecting && !isLoading && !noMoreData) {
        if (searchTerm === "") {
          // デフォルトモードなら現在の count を引数に渡す
          fetchMoreAttributes(count);
        } else {
          // 検索モードの場合は、次のページ取得のため count を更新
          setCount((prev) => prev + 1);
        }
      }
    },
    [isLoading, noMoreData, searchTerm, fetchMoreAttributes, count]
  );

  // モーダル表示時や言語変更時にデフォルトデータをリセットする
  useEffect(() => {
    if (props.isVisible) {
      resetAttributes();
    }
  }, [props.lan, props.isVisible, resetAttributes]);

  // IntersectionObserver の設定
  useEffect(() => {
    if (!props.isVisible) return;
    if (!loaderRef.current) return;

    const observer = new IntersectionObserver(handleObserver, {
      root: scrollContainerRef.current,
      rootMargin: "200px",
      threshold: 0.1,
    });

    observer.observe(loaderRef.current);
    return () => {

      observer.disconnect();
    };
  }, [props.isVisible, handleObserver]);

  // 入力中は単に入力値を更新し、結果表示はクリアする（＝何も表示しない）
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value.trim() === "") {
      // 入力欄が空になったら、デフォルトモードへ切り替え
      setSearchTerm("");
      setCount(0);
      setShowattribute([]);
      setNoMoreData(false);
      resetAttributes();
    } else {
      // 文字が入力され始めた場合は、結果をクリアして何も表示しない
      setShowattribute([]);
    }
  };

  // エンター送信時に検索実行
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const trimmed = inputValue.trim();
    if (trimmed === "") {
      // 空ならデフォルトモードへ切り替え
      setCount(0);
      setShowattribute([]);
      setSearchTerm("");
      resetAttributes();
    } else {
      // 新規検索の場合は count をリセットし、前回の検索結果をクリア
      setCount(0);
      setShowattribute([]);
      setSearchTerm(trimmed);
    }
  };

  // searchTerm と count の変更で検索結果を取得（検索モード）
  useEffect(() => {
    if (!props.isVisible) return;
    if (searchTerm !== "") {
      const fetchSearchResults = async () => {
        if (count === 0) {
          setShowattribute([]);
        }
        const result = await searchAttributes(searchTerm, props.lan, count);
        if (count === 0) {
          setShowattribute(result.res);
        } else {
          setShowattribute((prev) => [...prev, ...result.res]);
        }
        if (result.res.length < 10) {
          setNoMoreData(true);
        }
      };
      fetchSearchResults();
    }
  }, [props.isVisible, searchTerm, count, searchAttributes, props.lan]);

  return (
    <Dialog
      open={props.isVisible}
      onClose={props.handleClose}
      style={{ maxHeight: "70vh", minHeight: "200px", overflowY: "auto" }}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogContent
        ref={scrollContainerRef}
        className="card shadow-xss rounded-xxl border-0 ps-0 pt-1 pe-0 pb-3 mt-1 mb-1"
        style={{ maxHeight: "40vh", minHeight: "200px", overflowY: "auto" }}
      >
        <form className="pt-0 pb-0 ms-auto" onSubmit={handleSearchSubmit}>
          <div className="search-form-2 mt-1 mb-3 me-5">
            <i className="ti-search font-xss"></i>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-light-sm h50"
              placeholder={t("AttributesModal3")}
            />
          </div>
        </form>
        <div className="position-absolute right-0 top-5 pointer me-3" onClick={props.handleClose}>
          <i className="ti-close text-grey-400 fw-700 font-xsss"></i>
        </div>
        <div className="position-absolute left-0 top-5 pointer ms-2">
          <h2 className="card border-0 shadow-md mt-2 ms-2 ps-2 pe-2 pt-2 pb-2 font-xss lh-1 text-dark fw-600">
            {t("AttributesModal1")}
          </h2>
        </div>
        <div className="row justify-content-center mb-0">
          {showattribute.map((attribute) => (
            <AttributeEachforModal
              key={attribute.id}
              attributeData={attribute}
              handleClose={props.handleClose}
            />
          ))}
        </div>
        {/* loader の高さは発火を確実にするため 1px としています */}
        <div
          className="card"
          ref={loaderRef}
          style={{ height: "1px", margin: "0 auto", position: "relative", zIndex: 10 }}
        ></div>
        {isLoading && (
          <div className="card-body p-0 mb-3">
            <div className="snippet mt-2" data-title=".dot-typing">
              <div className="stage">
                <div className="dot-typing"></div>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AttributesModal;
