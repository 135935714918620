import React, { useEffect, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { ApiContext } from "../context/ApiContext";
import { useTranslation } from 'react-i18next';


const Profiledetail = () => {
  const { profile, getFollowerFollowingCounts } = useContext(ApiContext);
  const { t } = useTranslation();
  const [counts, setCounts] = useState({ following_count: 0, followed_count: 0, community_count: 0 });

  useEffect(() => {
    if (profile && profile.userProfile) {
      getFollowerFollowingCounts(profile.userProfile).then(data => setCounts(data));
    }
  }, [profile, getFollowerFollowingCounts]);

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
          {profile.caption}
        </p>
      </div>
      <div className="card-body d-flex pt-0">
        <i className="feather-user text-grey-500 me-3 font-lg"></i>
        <Link to={`/following?user_id=${profile.userProfile}`}>
            <h4 className="fw-700 text-grey-600 font-xssss mt-1 pointer">
            {counts.following_count} {t('Profiledetail1')}
            </h4>
          </Link>
      </div>
      <div className="card-body d-flex pt-0">
        <i className="feather-user text-grey-500 me-3 font-lg"></i>
        <Link to={`/followed?user_id=${profile.userProfile}`}>
          <h4 className="fw-700 text-grey-600 font-xssss mt-1 pointer">
          {counts.followed_count} {t('Profiledetail2')}
          </h4>
        </Link>
      </div>
      <div className="card-body d-flex pt-0">
        <i className="feather-users text-grey-500 me-3 font-lg"></i>
        <Link to={`/communitylist?user_id=${profile.userProfile}`}>
          <h4 className="fw-700 text-grey-600 font-xssss mt-1 pointer">
          {counts.community_count} {t('Profiledetail3')}
          </h4>
        </Link>
      </div>
    </div>
  );
};

export default Profiledetail;
