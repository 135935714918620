import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { ApiContext } from "../context/ApiContext";
import SpecificUserpagePicture from "../components/SpecificUserpagePicture";
import SpecificUserpageEach from "../components/SpecificUserpageEach";
import SpecificUserProfiledetail from "../components/SpecificUserProfileDetail";
import AdvertisementView from "../components/AdvertisementView";
import NoPostYetforSpecificUser from "../components/NoPostYetforSpecificUser";
import Postview from "./Postview";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import axios from "axios";

const SpecificUserpage = () => {
  const { profile, postsforintuser, intuser,newUserIntUser, ads, userpostWithScroll,randomAds, fetchRandomAds } = useContext(ApiContext);
  const { t } = useTranslation();
  const [token] = useState(localStorage.getItem('current-token'));

  const { userid } = useParams();

  useEffect(() => {
    newUserIntUser(userid);
    window.scrollTo({ left: 0, top: 0 });
    fetchRandomAds();
  }, [userid]);

  const [count, setCount] = useState(0);
  const [postsbyscroll, setPostsbyscroll] = useState([]);
  const loaderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const result = await userpostWithScroll(count, userid);
    if (result && result.res) {
      setPostsbyscroll(prevItems => [...prevItems, ...result.res]);
    }
    setIsLoading(false);
  };

  const handleObserver = (entities, observer) => {
    const target = entities[0];

    if (target.isIntersecting && !isLoading) {
      setCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [count, userid]);

  useEffect(() => {
    // ユーザーIDが変わったときに状態をリセット
    setCount(0);
    setPostsbyscroll([]);
    newUserIntUser(userid);
    window.scrollTo({ left: 0, top: 0 });
  }, [userid]);


// 例: ProfilePage.jsx 内
const { profileid } = useParams();

useEffect(() => {
  // ログイン状態（profile.id が存在する場合）のみログを記録
  if (profile && profile.id) {
    axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/access-log/`,
      { type: "profile", profile_id: intuser.id },
      { headers: { Authorization: `Token ${token}` } }
    ).catch((err) => {
      console.error("Profile access log error:", err);
    });
  }
}, [userid, profile, token]);

  

  return (
    <Fragment>
      <Helmet>
        <title>
          {intuser.nickName
            ? `${intuser.nickName} | Tatami`
            : "Tatami | ユーザープロフィール"}
        </title>
        <meta
          name="description"
          content={
            intuser.description ||
            "Tatamiユーザープロフィールページ。経済、ガジェット、スポーツ、先端科学技術など、多彩な情報が集まるTatamiで、ユーザーのプロフィールを確認できます。"
          }
        />
        <meta
          property="og:title"
          content={
            intuser.nickName
              ? `${intuser.nickName} | Tatami`
              : "Tatami | ユーザープロフィール"
          }
        />
        <meta
          property="og:description"
          content={
            intuser.description ||
            "Tatamiユーザープロフィールページ。経済、ガジェット、スポーツ、先端科学技術など、多彩な情報が集まるTatamiで、ユーザーのプロフィールを確認できます。"
          }
        />
        <meta
          property="og:image"
          content={
            intuser.img
              ? `${process.env.REACT_APP_API_URL}${intuser.img}`
              : "https://tatami.site/default_user_image.jpg"
          }
        />
        <meta property="og:url" content={`https://tatami.site/user/${userid}`} />
        <meta
          property="twitter:title"
          content={
            intuser.nickName
              ? `${intuser.nickName} | Tatami`
              : "Tatami | ユーザープロフィール"
          }
        />
        <meta
          property="twitter:description"
          content={
            intuser.description ||
            "Tatamiユーザープロフィールページ。経済、ガジェット、スポーツ、先端科学技術など、多彩な情報が集まるTatamiで、ユーザーのプロフィールを確認できます。"
          }
        />
        <meta
          property="twitter:image"
          content={
            intuser.img
              ? `${process.env.REACT_APP_API_URL}${intuser.img}`
              : "https://tatami.site/default_user_image.jpg"
          }
        />
        <meta property="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Person",
            "name": intuser.nickName || "Tatami ユーザープロフィール",
            "description": intuser.description || "Tatamiユーザープロフィールページ。",
            "image": intuser.img
              ? `${process.env.REACT_APP_API_URL}${intuser.img}`
              : "https://tatami.site/default_user_image.jpg",
            "url": `https://tatami.site/user/${userid}`
          })}
        </script>
      </Helmet>
      <Header />
      <Leftnav />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left ps-2 pe-2">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <SpecificUserpageEach userid={userid} />
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-3">
                <SpecificUserProfiledetail />
                {/* <SpecificUserpagePicture /> */}
              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {postsbyscroll &&
                  postsbyscroll.map((post, index) => (
                    <Fragment key={index}>
                      <Postview
                        postData={post}
                        profileData={post.userPostProfile}
                        reposting={post.reposting}
                        repostingProfileData={post.repostingProfile || null}
                        commentedCounts={post.commentedCounts}
                        repostedTimes={post.repostedTimes}
                        likedTimes={post.likedTimes}
                        monopage = {post.reviewToPage}  
                      />
                  { index % 3 === 2 && randomAds.length > 0 ? (
                    <AdvertisementView
                      key={`ad-${index}`}
                      item={randomAds[parseInt(index / 3) % randomAds.length]}
                    />
                  ) : null}
                    </Fragment>
                  ))}
                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }} className="mb-5"></div>
                {isLoading && (
                  <div className="card-body p-0 mb-3">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                      <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {postsbyscroll.length === 0 && <NoPostYetforSpecificUser />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default SpecificUserpage;
