import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardActions, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    maxWidth: 360,
    marginBottom: "1rem",
    height: 200, // カード全体の固定高さ
  },
  cardContent: {
    minHeight: "130px",
    overflow: "hidden", // はみ出た内容は隠す
  },
  media: {
    height: 140,
  },
});

const RecommenduserEach = ({ profileeach }) => {
  const classes = useStyles();
  const { profile, newRequestFriend, deleteFriendRequest, getFollowCheck } = useContext(ApiContext);
  const history = useHistory();
  const { t } = useTranslation();

  // "community"か"profile"かの判定
  const isCommunity = profileeach.item_type === "community";
  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    let isMounted = true; // マウントチェック用フラグ

    // profile が未定義の場合は処理をスキップ
    if (!isCommunity && profile && profile.id && profileeach.userProfile) {
      const checkFollowStatus = async () => {
        try {
          const followCheck = await getFollowCheck(profileeach.userProfile);
          if (isMounted) {
            setIsFollowed(followCheck.following);
          }
        } catch (error) {
          console.error("Follow check error:", error);
        }
      };
      checkFollowStatus();
    }
    return () => {
      isMounted = false;
    };
  }, [profile, profileeach.userProfile, isCommunity, getFollowCheck]);

  const createNewRequest = async () => {
    if (!profileeach.userProfile) return;
    try {
      const uploadDataAsk = new FormData();
      uploadDataAsk.append("askTo", profileeach.userProfile);
      uploadDataAsk.append("approved", false);
      await newRequestFriend(uploadDataAsk);
      setIsFollowed(true);
    } catch (error) {
      console.error("Error creating request:", error);
    }
  };

  const handleUnfollow = async () => {
    if (!profileeach.userProfile) return;
    try {
      await deleteFriendRequest(profile.userProfile, profileeach.userProfile);
      setIsFollowed(false);
    } catch (error) {
      console.error("Error during unfollow:", error);
    }
  };

  // クリック時の遷移先を切り替え
  const toSpecificPage = () => {
    if (isCommunity) {
      history.push(`/communitypage/${profileeach.id}`);
    } else {
      if (profile && profile.userProfile === profileeach.userProfile) {
        history.push("/userpage");
      } else {
        history.push(`/user/${profileeach.userProfile}`);
      }
    }
  };

  const [newPostFlag, setNewPostFlag] = useState(false);

  // コミュニティの場合、ログインしているときは新規投稿の有無をチェックする
  useEffect(() => {
    if (isCommunity && profile && profile.id) {
      fetch(`${process.env.REACT_APP_API_URL}/api/user/community-new-post/?communityid=${profileeach.id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("current-token")}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setNewPostFlag(data.newpost_flag);
        })
        .catch(error => {
          console.error("Error fetching new post flag:", error);
        });
    }
  }, [isCommunity, profile, profileeach.id]);

  // 作成日を元に「New」表示
  const createdDate = new Date(profileeach.created_on || profileeach.createdon);
  const today = new Date();
  const threeDaysAgo = new Date(today);
  threeDaysAgo.setDate(today.getDate() - 2);
  const isNew = createdDate > threeDaysAgo;
  

  return (
    <div className="col-md-3 col-sm-4 pe-2 ps-2">
      <Card className={clsx(classes.card, "card shadow-xss")}>
        {isNew && (
          <a
            className=" fw-600 ms-auto font-xsssss text-primary ps-2 pe-2 pb-0 pt-0 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm position-absolute top---15 mb-5 ms-2 right-15"
          >
            New
          </a>
        )}
                {isCommunity && newPostFlag && (
          <a
            className="fw-600 ms-auto font-xsssss text-white ps-2 pe-2 pb-0 pt-0 lh-32 text-uppercase rounded-3 ls-2 bg-current d-inline-block shadow-sm position-absolute top---15 mb-5 ms-2 left-15"
          >
          未読
          </a>
        )}

        <CardContent className={clsx(classes.cardContent, "text-center", "mycard")}>
          {isCommunity ? (
            profileeach.image ? (
              <figure
                className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
                onClick={toSpecificPage}
              >
                <img
                  src={
                    profileeach.image.slice(0, 4) === "http"
                      ? profileeach.image
                      : process.env.REACT_APP_API_URL + profileeach.image
                  }
                  alt="community"
                  className="float-right p-0 bg-white rounded-3 w-100 shadow-xss"
                />
              </figure>
            ) : (
              <figure
                className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
                onClick={toSpecificPage}
              >
                <img
                  src="default_community_image_url" // デフォルトのコミュニティ画像URL
                  alt="community"
                  className="float-right p-0 bg-white rounded-3 w-100 shadow-xss"
                />
              </figure>
            )
          ) : (
            profileeach.img ? (
              <figure
                className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
                onClick={toSpecificPage}
              >
                <img
                  src={
                    profileeach.img.slice(0, 4) === "http"
                      ? profileeach.img
                      : process.env.REACT_APP_API_URL + profileeach.img
                  }
                  alt="avatar"
                  className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                />
              </figure>
            ) : (
              <figure
                className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
                onClick={toSpecificPage}
              >
                <img
                  src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                  alt="avatar"
                  className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                />
              </figure>
            )
          )}

          <h4 className="fw-700 font-xsss mt-3 mb-0">
            {isCommunity ? profileeach.title : profileeach.nickName}
          </h4>
          <h4 className="fw-700 font-xsssss text-grey-600 mt-2 mb-0">
  {isCommunity
    ? profileeach.description && profileeach.description.length > 20
      ? profileeach.description.substring(0, 20) + "..."
      : profileeach.description
    : ""}
</h4>
        </CardContent>

        <CardActions className="mycard">
          <Grid container justifyContent="center">
            {profile && profile.id ? (
              <>
                {isCommunity ? (
                  <Grid item>
                    <button
                      className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                      onClick={toSpecificPage}
                    >
                      {t("RecommenduserEach4")}
                    </button>
                  </Grid>
                ) : (
                  <>
                    {profileeach.id === profile.id ? (
                      <Grid item>
                        <button
                          className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white"
                          onClick={toSpecificPage}
                        >
                          {t("RecommenduserEach1")}
                        </button>
                      </Grid>
                    ) : (
                      <Grid item>
                        {!isFollowed ? (
                          <button
                            className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                            onClick={createNewRequest}
                          >
                            {t("RecommenduserEach2")}
                          </button>
                        ) : (
                          <button
                            className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-grey font-xsssss fw-700 ls-lg text-white"
                            onClick={handleUnfollow}
                          >
                            {t("RecommenduserEach3")}
                          </button>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </>
            ) : (
              <Grid item>
                <button
                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                  onClick={toSpecificPage}
                >
                  {isCommunity ? "サークルページ" : "ユーザーページ"}
                </button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};

export default RecommenduserEach;
