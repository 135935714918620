import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ApiContext } from "../context/ApiContext";
import NotificationEach from "./NotificationEach";
import axios from "axios";

const NotificationModal = (props) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const scrollContainerRef = useRef(null);
  const loaderRef = useRef(null);

  const token = localStorage.getItem("current-token");

  // 無限スクロール用の通知取得関数
  const fetchNotifications = async (page) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/notifications/all/`,
        {
          params: { count: page },
          headers: { Authorization: `Token ${token}` },
        }
      );
      if (!res.data.notifications || res.data.notifications.length === 0) {
        setNoMoreData(true);
      } else {
        setNotifications((prev) => [...prev, ...res.data.notifications]);
      }
    } catch (error) {
      console.error("通知取得エラー:", error);
    }
    setIsLoading(false);
  };
  
  // count 更新時に通知を取得
  useEffect(() => {
    fetchNotifications(count);
  }, [count]);

  // handleObserver を useCallback で安定化
  const handleObserver = useCallback((entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading && !noMoreData) {
      setCount((prev) => prev + 1);
    }
  }, [isLoading, noMoreData]);

  // IntersectionObserver の設定（依存配列は初回のみ）
  useEffect(() => {
    const options = {
      root: scrollContainerRef.current,
      rootMargin: "200px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  return (
    <Dialog
      open={props.isVisible}
      onClose={props.handleClose}
      fullWidth
      maxWidth="sm"
      style={{ minHeight:"70vh",maxHeight: "100vh", overflowY: "auto" }}
    >
      <DialogContent ref={scrollContainerRef} style={{  minHeight:"70vh",maxHeight: "100vh", overflowY: "auto" }}>
      <div
          className="position-absolute right-0 top-5 pointer me-3"
          onClick={() => props.handleClose()}
        >
          <i className="ti-close text-grey-400 fw-700 font-xsss"></i>
        </div>
        {notifications.map((noti) => (
          <NotificationEach key={noti.id} notiData={noti} notiType={noti.type} />
        ))}
        <div ref={loaderRef} style={{ height: "1px", margin: "0 auto", position: "relative", zIndex: 10 }} />
        {isLoading && <div>{t("Loading...")}</div>}
      </DialogContent>
    </Dialog>
  );
};

export default NotificationModal;
