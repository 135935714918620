import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { useParams} from "react-router-dom";
import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { ApiContext } from "../context/ApiContext";
import AttributeEachforModal from "./AttributeEachforModal";
import CommunityInviteModalEach from "./CommunityInviteModalEach";
import { Link, useLocation, useHistory } from "react-router-dom";
import { SnackbarContext } from "../context/SnackbarContext"; // snackbar用


const CommunityInviteModal = (props) => {
  const { getAttributesWithScroll, searchAttributes,profile, profilesWithScroll, searchProfiles ,profilesonlyWithScroll, searchProfilesOnly } = useContext(ApiContext);
  const [showattribute, setShowattribute] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [profilesbyscroll, setProfilesbyscroll] = useState([]);
  const [animate, setAnimate] = useState(false);
  const [showProfiles, setShowProfiles] = useState([]);
  const { communityid } = useParams();


  const scrollContainerRef = useRef(null);
  const loaderRef = useRef(null);
  const { t } = useTranslation();
  

  // isMountedRef を用意して、コンポーネントがマウントされているかを追跡
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const { newSnack } = useContext(SnackbarContext);

  const [isShowHowToModal, setShowHowToModal] = useState(false);
  const [isShowCreateCommunity, setShowCreateCommunity] = useState(false);
  const token = localStorage.getItem("current-token");

  const handleObserver = useCallback((entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading && !noMoreData) {
      setCount((prev) => prev + 1);
    }
  }, [isLoading, noMoreData]);
  

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    // 検索はエンター送信まで待つ
  };

  const fetchData = async () => {
    setIsLoading(true);
    const result = await profilesonlyWithScroll(count);
    if (isMountedRef.current) {
      // resultがundefinedの場合にも対応
      if (!result || result.length === 0) {
        setNoMoreData(true);
      } else {
        setProfilesbyscroll((prevItems) => [...prevItems, ...result]);
      }
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (searchTerm !== "") {
      if (count === 0) {
        setShowProfiles([]);
      }
      searchProfilesOnly(searchTerm, count).then((result) => {
        if (isMountedRef.current) {
          if (count === 0) {
            setShowProfiles(result.res);  // result.res をセット
          } else {
            setShowProfiles((prev) => [...prev, ...result.res]);  // result.res を展開
          }
          // 取得件数が limit 未満なら、これ以上読み込まないように noMoreData を true に設定
          if (result.res.length < 10) {
            setNoMoreData(true);
          }
        }
      });
    } else {
      if (count === 0) {
        setProfilesbyscroll([]);
      }
      fetchData();
    }
  }, [count, searchTerm]);
  
  
  
  

  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    if (!props.isVisible) return;
  
    // DOM のレンダリング完了を待つために少し遅延させる
    const timer = setTimeout(() => {
      if (!loaderRef.current) return;
  
      const observer = new IntersectionObserver(handleObserver, {
        root: scrollContainerRef.current,
        rootMargin: "200px",
        threshold: 0.1,
      });
      observer.observe(loaderRef.current);
  
      // クリーンアップ用に observer を返す
      return () => observer.disconnect();
    }, 200); // 200ms の遅延を設定
  
    return () => clearTimeout(timer);
  }, [props.isVisible, handleObserver]);
  


  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    setCount(0);
    setProfilesbyscroll([]);
    if (!hasVisited) {
      setShowHowToModal(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const defaultProfiles = profilesbyscroll.map((profileItem, index) => (
    <CommunityInviteModalEach key={index} profileeach={profileItem} />
  ));
  const listProfiles = showProfiles.map((profileItem) => (
    <CommunityInviteModalEach key={profileItem.id} profileeach={profileItem} />
  ));

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const trimmed = inputValue.trim();
    if (trimmed === "") {
      setCount(0);
      setProfilesbyscroll([]);
      setShowProfiles([]);
      setSearchTerm("");
    } else {
      setCount(0);
      setShowProfiles([]);
      setSearchTerm(trimmed);
    }
  };

  const handleCopyUrl = () => {
    // 例：https://tatami.site/communitylogin/:communityid
    const communityUrl = `https://tatami.site/communitylogin/${communityid}`;
    navigator.clipboard.writeText(communityUrl)
      .then(() => newSnack("success", "招待URLをコピーしました"))
      .catch(() => newSnack("error", "URLのコピーに失敗しました"));
  };




  return (
    <Dialog
      open={props.isVisible}
      onClose={props.handleClose}
      style={{ maxHeight: "90vh", minHeight: "500px", overflowY: "auto" }}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogContent
        ref={scrollContainerRef}
        className="card shadow-xss rounded-xxl border-0 ps-0 pt-1 pe-0 pb-3 mt-1 mb-1"
        style={{ maxHeight: "90vh", minHeight: "500px", overflowY: "auto" }}
      >


        <form className="pt-0 pb-0 ms-auto" onSubmit={handleSearchSubmit}>
          <div className="search-form-2 mt-1 mb-3 me-5">
            <i className="ti-search font-xss"></i>
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-light-sm h50"
              placeholder="ユーザーを検索"
            />
          </div>
        </form>
        <div className="position-absolute right-0 top-5 pointer me-3" onClick={props.handleClose}>
          <i className="ti-close text-grey-400 fw-700 font-xsss"></i>
        </div>
        <div className="position-absolute left-0 top-5 pointer ms-2">
          <h2 className="card border-0 shadow-md mt-2 ms-2 ps-2 pe-2 pt-2 pb-2 font-xss lh-1 text-dark fw-600">
          招待
          </h2>
        </div>
        <div className="row justify-content-center mb-0">
                  <span className="pointer font-xsssss fw-700 me-2 p-1 lh-32 text-center justify-content-center rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm w300 mb-0 d-flex" onClick={handleCopyUrl}>
                    <i className="feather-download text-white btn-round-sm font-xs"></i>
                    <h4 className="font-xssss text-white fw-600 mt-2 text-center">
                      クリックして招待URLをコピー
                    </h4>
                  </span>
                  <h4 className="font-xssss me-1 ms-1 text-grey-500 fw-500 text-center mt-1">
                      ※Tatamiユーザー以外を招待する場合は、招待URLをコピーして共有してください
                    </h4>
        <div className="row justify-content-center mb-0">
  {searchTerm !== "" ? listProfiles : defaultProfiles}
</div>

        </div>
        {/* loader の高さは発火を確実にするため 1px としています */}
        <div
          className="card"
          ref={loaderRef}
          style={{ height: "10px", margin: "0 auto", position: "relative", zIndex: 10 }}
        ></div>
        {isLoading && (
          <div className="card-body p-0 mb-3">
            <div className="snippet mt-2" data-title=".dot-typing">
              <div className="stage">
                <div className="dot-typing"></div>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CommunityInviteModal;
