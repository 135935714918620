import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardActionArea, CardContent, Typography } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { ApiContext } from "../context/ApiContext";
import { SnackbarContext } from "../context/SnackbarContext";

const useStyles = makeStyles({
  card: {
    maxWidth: 400,
    marginBottom: "1rem",
    position: "relative",
    maxHeight: 330,
    minHeight: 350,
    overflow: "hidden",
  },
  cardContent: {
    minHeight: "130px",
    maxHeight: "300px",
    overflow: "hidden",
  },
  media: {
    height: 140,
    width: "100%",
    objectFit: "contain", // 画像がはみ出さないように
    display: "block",
    position: "relative",
    zIndex: 2,
  },
  moveToBtn: {
    position: "absolute",
    right: "10px",
    bottom: "20px",
    zIndex: "3",
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    filter: "blur(8px)",
    opacity: 0.3,
    zIndex: 1,
  },
  mediaContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 140,
  },
  // 新たに affiliate 用のコンテナを定義
  affiliateContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    maxHeight: 140,
    maxWidth: "100%",
    zIndex: 2,
    overflow: "hidden",
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      display: "block",
      margin: "0 auto",
    },
  },
});

const AdvertisementViewforPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { newSnack } = React.useContext(SnackbarContext);
  const { addAdsCnt } = React.useContext(ApiContext);

  useEffect(() => {
    if (props.item && props.item.type === 0) {
      addAdsCnt(props.item.id);
    }
  }, [props.item, addAdsCnt]);

  const toAdsDetail = () => {
    if (props.item.type === 1) addAdsCnt(props.item.id);
    if (!props.item.url) return;
    window.location = props.item.url;
  };

  const getImgUrl = () => {
    if (!props.item.img) return "";
    return props.item.img.slice(0, 4) === "http"
      ? props.item.img
      : process.env.REACT_APP_API_URL + props.item.img;
  };

  return (
    <div className="col-md-6 col-sm-12">
      <Card className={clsx(classes.card, "card border-light-md shadow-xss")}>
        <a
          className={clsx(classes.moveToBtn, "pointer")}
        >
          <i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i>
        </a>
        <CardActionArea>
          <div className={classes.mediaContainer}>
            {props.item.img && !props.item.is_affiliate && (
              <div
                className={classes.backgroundImage}
                style={{ backgroundImage: `url(${getImgUrl()})` }}
              ></div>
            )}
            {props.item.is_affiliate && props.item.affiliate_html ? (
              // affiliateの場合：ラッパーに affiliateContainer を設定して中央揃え＆画像のサイズ制限を適用
              <div
                className={classes.affiliateContainer}
                dangerouslySetInnerHTML={{ __html: props.item.affiliate_html }}
              />
            ) : props.item.img ? (
              // 通常の場合：画像に対して objectFit: contain を指定
              <img
                className={classes.media}
                src={getImgUrl()}
                alt="Advertisement"
                onClick={toAdsDetail}
              />
            ) : null}
          </div>
          <CardContent className={clsx(classes.cardContent, "mycard")}>
            <h4 className="fw-600 font-xsssss mt-auto position-absolute top---60 text-grey-600">
              PR
            </h4>
            <figure className="mt-0 avatar me-0 pointer mb-1 fw-600 ms-auto position-absolute top---50 mb-5 ms-2 mt-0 right-15 d-flex">
              {props.item.userProfile && props.item.userProfile.img ? (
                <img
                  src={
                    props.item.userProfile.img.slice(0, 4) === "http"
                      ? props.item.userProfile.img
                      : process.env.REACT_APP_API_URL + props.item.userProfile.img
                  }
                  alt="default avatar"
                  className="shadow-md me-1 rounded-circle w25"
                />
              ) : (
                <img
                  src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                  alt="default avatar"
                  className="shadow-md rounded-circle w25"
                />
              )}
            </figure>
            <Typography gutterBottom variant="h7" component="h2" className="mt-3 black-typography">
              {props.item.content}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default AdvertisementViewforPage;
