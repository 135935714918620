import React, {
  useContext,
  Fragment,
  // useCallback, useState
} from "react";
import { ApiContext } from "../context/ApiContext";
import { Link } from "react-router-dom";
import {
  Button,
  // makeStyles
} from "@material-ui/core";
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; 
import { SnackbarContext } from "../context/SnackbarContext";
import { NavbarBrand } from "react-bootstrap";

export const ASPECT_RATIO = 1 / 1;
export const CROP_WIDTH = 400;




const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: 30,
      minWidth: "100%",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      flexFlow: "column",
      "& .file-upload-container": {
        width: 500,
        marginTop: 10,
        "& .button": {
          backgroundColor: "#00A0FF",
          color: "white",
        },
      },
    },
    validationWrapper: {
      float: "right"
    }
  }
})
// console.log(i18n.language)
const ChangeLanguage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    profile,
    //  editedProfile, setEditedProfile, deleteProfile, setCover, coverBack, createProfile, editProfile
  } = useContext(ApiContext);

  const { newSnack } = useContext(SnackbarContext);

  const [cookies, setCookie, removeCookie] = useCookies(["current-token"]);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Fragment>
    <Header />
    <Leftnav />
    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                <Link to="/settings" className="d-inline-block mt-2">
                  <i className="ti-arrow-left font-sm text-white"></i>
                </Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                  {t('ChangeLanguage1')}
                </h4>
              </div>
              <div className="card-body p-lg-5 p-4 w-100 border-0">
                <div className="row justify-content-center">
                  <div className="col-lg-4 text-center">
                    {profile.img ? (
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img
                          src={profile.img}
                          alt="avater"
                          className="shadow-sm rounded-3 w-100"
                        />
                      </figure>
                    ) : (
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img
                          src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                          alt="avater"
                          className="shadow-sm rounded-3 w-100"
                        />
                      </figure>
                    )}
                    <h2 className="fw-700 font-sm text-grey-900 mt-3">
                      {profile.nickName}
                    </h2>
                    <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">
                      {profile.created_on}
                    </h4>
                  </div>
                </div>

                <div className="row justify-content-center mb-4">

                  <div className="col-lg-12 text-center">
                  {
                    i18n.language === "ja" ?
                    <Button
                    className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 mt-3 ms-1 me-1"
                    variant="contained"
                    component="label"
                    // onClick={() => {changeLanguage('ja'); newSnack("error", "日本語に切り替えました");}} // 日本語に切り替え
                  >
                    {t('ChangeLanguage2')}
                  </Button>:
                  <Button
                  className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 mt-3 ms-1 me-1"
                  variant="contained"
                  component="label"
                  onClick={() => {changeLanguage('ja'); newSnack("error", "日本語に切り替えました");}} // 日本語に切り替え
                >
                  {t('ChangeLanguage3')}
                </Button>
                  }
                {i18n.language ==="en" ?
                <Button
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 mt-3 ms-1 me-1"
                variant="contained"
                component="label"
                // onClick={() => {changeLanguage('en'); newSnack("error", "Setup language has been changed to English")}} // 英語に切り替え
              >
                {t('ChangeLanguage4')}
              </Button>:
                <Button
                className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 mt-3 ms-1 me-1"
                variant="contained"
                component="label"
                onClick={() => {changeLanguage('en'); newSnack("error", "Setup language has been changed to English")}} // 英語に切り替え
              >
                {t('ChangeLanguage5')}
              </Button>
              }  


                  <Link
                      to="/settings">
                    <Button
                      className="bg-grey-500 text-center text-dark font-xsss fw-600 p-3 ms-1 me-1 w175 rounded-3 mt-3"
                      variant="contained"
                      component="label"
                    >
                      {t('ChangeLanguage6')}

      
                    </Button>
                  </Link>
                
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <Appfooter />
  </Fragment>
  );
};

export default ChangeLanguage;
