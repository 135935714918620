import React, { Fragment, useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Tooltip, Fab } from "@material-ui/core";
import Header from "../components/Header";
import Lightbox from "react-image-lightbox";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { green } from "@material-ui/core/colors";
import { ApiContext } from "../context/ApiContext";
import { SnackbarContext } from "../context/SnackbarContext";
import FollowingPageListEach from "./FollowingPageListEach";
import copy from '../context/clipboard';
import { AddComment } from "@material-ui/icons";
// import Profiledetail from '../components/Profiledetail';
// import UserpagePicture from "../components/UserpagePicture";
import FollowEach from "../components/FollowEach";
import { useCookies } from "react-cookie";
import LoginComponent from "../components/LoginComponent";
import { useTranslation } from 'react-i18next';
import axios from "axios";
// import ProfileRecommender from "../components/ProfileRecommender";
import RecommenduserEach from "../components/RecommenduserEach";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CreateCommunityPost from "../components/CreateCommunityPost";
import DataModal from "../components/DataModal";

const useStyles = makeStyles((theme) => {
  return {
    PostButton: {
      backgroundColor: green[400],
      "&:hover": {
        backgroundColor: green[200],
      }
    },
    PostButtonContainer: {
      position: "fixed",
      width: "100%",
      zIndex: "999",
      left: "350px",
      bottom: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('xs')]: { 
        left: '150px',
      },
    },
    DataButton: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      },
    DataButtonContainer: {
        position: "fixed",
        width: "100%",
        zIndex: "999",
        left: "350px",
        bottom: "135px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          left: '150px',
        },
    },
  };
});

const CommunityPageBook = () => {
  const { profile, fetchFollowedUsersWithScroll,newRequestFriend, intuser, getFollowCheck, deleteFriendRequest, newUserIntUser, fetchUserMonoPagesWithFiles, fetchCommunityMonoPagesWithFiles  } = useContext(ApiContext);
  const location = useLocation();
  const { newSnack } = useContext(SnackbarContext);
  const classes = useStyles();

  // クエリパラメータから user_id を取得
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get('user_id');

  const [pages, setPages] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [token] = useState(localStorage.getItem('current-token'));
  const loaderRef = useRef(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false); // フォロー状態を保持するstate
  const [isShowDataModal, setShowDataModal] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const { communityid } = useParams();
  const [communityData, setCommunityData] = useState(null);

  const { t } = useTranslation();
  const handleDataClick = () => {
    if (!profile.userProfile) {
      newSnack("error", t('Header14'));
      return;
    }
    setShowDataModal(true);
  }

  const handleDataModalClose = () => {
    setShowDataModal(false);
  }

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };



  useEffect(() => {
    const fetchData = async () => {
      
      setIsLoading(true);
      
      const newPages = await fetchCommunityMonoPagesWithFiles(communityid, count);
      if (newPages.length === 0) {
        setNoMoreData(true);  // これ以上データがない場合はフラグを立てる
      } else {
        setPages((prevPages) => [...prevPages, ...newPages]);
      }
      setIsLoading(false);
    };
  
    fetchData();
  }, [count, communityid]);
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading && !noMoreData) {
          setCount((prevCount) => prevCount + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef, isLoading, noMoreData]);





  // コミュニティ情報の取得（例）
  useEffect(() => {
    const fetchCommunity = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/community/${communityid}/`,
          { headers: { Authorization: `Token ${token}` } }
        );
        setCommunityData(res.data);
      } catch (error) {
        console.error("コミュニティ情報の取得エラー", error);
      }
    };
    fetchCommunity();
  }, [communityid, token]);

  const [communityStatus, setCommunityStatus] = useState("none");

  // communityStatus を取得する関数
  const fetchCommunityStatus = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/community_status/${communityid}/`,
        {
          headers: token ? { Authorization: `Token ${token}` } : {},
        }
      );
      // レスポンス全体を state に保存する
      setCommunityStatus(res.data);
    } catch (error) {
      console.error("コミュニティステータス取得エラー:", error);
    }
  };
  


  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
      <DataModal
        isVisible={isShowDataModal}
        handleClose={handleDataModalClose}
        
      />
{isOpen && (
  <CreateCommunityPost
    isVisible={isOpen}
    profileData={profile}
    handleClose={closeDialog}
    communityId={communityid}
  />
)}

<div className={ classes.PostButtonContainer }>
                <Tooltip title="Post Comment" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={openDialog}
                    >
                      <AddComment />
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={()=>newSnack("error", t('Home3'))}
                    >
                      <AddComment />
                    </Fab>
                  )}
                </Tooltip>
              </div>
              <div className={ classes.DataButtonContainer }>
                <Tooltip title="Post Data" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={handleDataClick}
                    >

                    <i className="feather-file-plus font-xl text-white"></i>

                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={()=>newSnack("error", "投稿するにはログインしてください")}
                    >
                    <i className="feather-file-plus font-xl text-white"></i>
                    </Fab>
                  )}
                </Tooltip>
              </div>
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left ps-2 pe-2">
            <div className="row">
              <div className="col-xl-12 mb-3">
                <div className="card w-100 border-0 p-0 pt-10 mt-0 bg-white shadow-xss rounded-xxl">
                  {communityData?
                                  <div className="card-body  rounded-l position-relative h100 bg-image-cover rounded-xxxl bg-image-center" style={{backgroundImage: `url(${communityData.background_image.slice(0,4)==="http" ? communityData.background_image :process.env.REACT_APP_API_URL + communityData.background_image}`}} ></div>
                :<></>
                }


                 
                  <div className="card-body p-0 position-relative">
                    
        {communityData ? (
          <div>
            {isOpenModal && (
              <Lightbox
                mainSrc={communityData.image.slice(0,4)==="http" ? communityData.image :process.env.REACT_APP_API_URL + communityData.image}
                onCloseRequest={() => setIsOpenModal(false)}
              />
            )}
            <div onClick={() => setIsOpenModal(true)}>
              <figure
                className="avatar position-absolute w100 z-index-1"
                style={{ top: "-40px", left: "30px" }}
              >
                <img
                  src={communityData.image.slice(0,4)==="http" ? communityData.image :process.env.REACT_APP_API_URL + communityData.image}
                  alt="avater"
                  className="float-right p-1 bg-white rounded-xl border-white card w-100"
                />
              </figure>
            </div>
          </div>
        ) : (
          <figure
            className="avatar position-absolute w100 z-index-1"
            style={{ top: "-40px", left: "30px" }}
          >
            <img
              src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
              alt="avater"
              className="float-right p-1 bg-white rounded-xl border-white card w-100"
            />
          </figure>
        )}
        {communityData ? 
          <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
          {communityData.title}
          <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
            {communityData.description && communityData.description.length > 20
      ? communityData.description.substring(0, 20) + "..."
      : communityData.description}
          </span>
        </h4>:
        <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
        {t('SpecificUserpageEach2')}
        <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
        {t('SpecificUserpageEach3')}
        </span>
        </h4>
        }
        
      </div>

                  <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <ul
                      className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to={`/communitypage/${communityid}`}
                          data-toggle="tab"
                        >
                          スレッド
                        </Link>
                      </li>
                      <li className="active list-inline-item me-5">
                        <Link
                          className="active fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to={`/communitypagebook/${communityid}`}
                          data-toggle="tab"
                        >
                          本棚
                        </Link>
                      </li>
                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to={`/communitypagemember/${communityid}`}
                          data-toggle="tab"
                        >
                          メンバー
                        </Link>
                      </li>
                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to={`/communitypagewaiting/${communityid}`}
                          data-toggle="tab"
                        >
                          承認待ち
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-3">
                {/* <Profiledetail />
                                <UserpagePicture /> */}
              </div>
                <div className="col-xl-12 col-xxl-12 col-lg-12">
                <div className="row ps-2 pe-2">
                                {pages.map(zz =>
                                    <FollowingPageListEach
                                        key={zz.id}
                                        pageData={zz} />
                                )}
                            </div>
                                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }}></div>
                                {isLoading && (
                                    <div className="card-body p-0 mb-3">
                                        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                            <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                                                <div className="stage">
                                                    <div className="dot-typing"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        {!profile.id ?
                          <div className="text-center p-4 text-grey-600 fw-600">
                          ログインしてください
                        </div>:
                        (!communityStatus?.status ==="member"?
                        <div className="text-center p-4 text-grey-600 fw-600">
                          サークルに参加すると本棚を確認できます
                        </div>:

                        (!isLoading && pages.length === 0?
                          <div className="text-center p-4 text-grey-600 fw-600">
                          Bookがまだありません
                        </div>
                          :<></>))
                          }

            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default CommunityPageBook;
