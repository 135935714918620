import React, { useContext, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardActions, Grid } from "@material-ui/core";
import { SnackbarContext } from "../context/SnackbarContext";

const useStyles = makeStyles({
  card: {
    maxWidth: 360,
    marginBottom: "1rem",
    height: 180, // カード全体の固定高さ
  },
  cardContent: {
    minHeight: "20px",
    overflow: "hidden", // はみ出た内容は隠す
  },
  media: {
    height: 140,
  },
});

const CommunityInviteModalEach = ({ profileeach }) => {
  const classes = useStyles();
  const { profile, newRequestFriend, deleteFriendRequest, getFollowCheck } = useContext(ApiContext);
  const history = useHistory();
  const { t } = useTranslation();

  // "community"か"profile"かの判定
  const isCommunity = profileeach.item_type === "community";
  const [isFollowed, setIsFollowed] = useState(false);
  const { communityid } = useParams();
  const { newSnack } = useContext(SnackbarContext);
  const token = localStorage.getItem("current-token");


  // クリック時の遷移先を切り替え
  const toSpecificPage = () => {
    if (isCommunity) {
      history.push(`/communitypage/${profileeach.id}`);
    } else {
      if (profile && profile.userProfile === profileeach.userProfile) {
        history.push("/userpage");
      } else {
        history.push(`/user/${profileeach.userProfile}`);
      }
    }
  };

  // 作成日を元に「New」表示
  const createdDate = new Date(profileeach.created_on || profileeach.createdon);
  const today = new Date();
  const threeDaysAgo = new Date(today);
  threeDaysAgo.setDate(today.getDate() - 2);
  const isNew = createdDate > threeDaysAgo;


const createInvitation = async (communityid, invitedUserId, token, profile, newSnack) => {
  // ログインチェック
  if (!profile || !profile.id) {
    newSnack("error", "ログインしてください");
    return;
  }
  // ※ここでは、現在のユーザーがコミュニティのメンバーであるかは、CommunityPage などで取得済みの isMember を利用するか、
  // サーバー側で判定させるので、エラーが返ってくる場合に newSnack で表示する形でも可。

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/community/${communityid}/invite/`,
      { invited_user: invitedUserId },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    newSnack("success", "招待が送信されました");
    return res.data;
  } catch (error) {
    const errMsg = error.response?.data?.detail || "招待の送信に失敗しました";
    newSnack("error", errMsg);
  }
};

const handleInvitation = async () => {
  if (!profile || !profile.id) {
    newSnack("error", "ログインしてください");
    return;
  }
  // 招待を作成する（communityid は CommunityPage から渡す）
  await createInvitation(communityid, profileeach.userProfile, localStorage.getItem("current-token"), profile, newSnack);
};
  

  return (
    <div className="col-md-3 col-sm-4 pe-2 ps-2">
      <Card className={clsx(classes.card, "card shadow-xss")}>
        {/* {isNew && (
          <a
            className="fw-600 ms-auto font-xsssss text-primary ps-2 pe-2 pb-0 pt-0 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm position-absolute top---15 mb-5 ms-2 right-15"
          >
            New
          </a>
        )} */}

        <CardContent className={clsx(classes.cardContent, "text-center", "mycard")}>
            {profileeach.img ? (
              <figure
                className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
                onClick={toSpecificPage}
              >
                <img
                  src={
                    profileeach.img.slice(0, 4) === "http"
                      ? profileeach.img
                      : process.env.REACT_APP_API_URL + profileeach.img
                  }
                  alt="avatar"
                  className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                />
              </figure>
            ) : (
              <figure
                className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
                onClick={toSpecificPage}
              >
                <img
                  src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                  alt="avatar"
                  className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                />
              </figure>
            
          )}

          <h4 className="fw-700 font-xsss mt-3 mb-0">
            {profileeach.nickName}
          </h4>
          <h4 className="fw-700 font-xsssss text-grey-600 mt-2 mb-0">
</h4>
        </CardContent>

        <CardActions className="mycard">
          <Grid container justifyContent="center">
                      <Grid item>
                        <button
                          className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current pointer font-xsssss fw-700 ls-lg text-white"
                          style={{marginTop: "0px" }}
                          onClick={handleInvitation}
                        >
                          招待
                        </button>
                      </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};

export default CommunityInviteModalEach;
