import React from "react";
import { ApiContext } from "../context/ApiContext";
import  { useContext} from "react";
import { SnackbarContext } from "../context/SnackbarContext";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const NotificationEach = ({ notiData, notiType }) => {

  const {
    profile,
    getNotification,
    markNotificationAsRead
  } = useContext(ApiContext);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  // console.log(notiData)

// Book詳細への遷移関数
const toMonopage = async (pageId, notificationId) => {
  try {
    if (pageId) {
      await markNotificationAsRead(notificationId);
      getNotification();
      history.push(`/mono/${pageId}`);
    } else {
      throw new Error("Page not found");
      newSnack("error", 'Bookが見つかりません');
    }
  } catch (error) {
    await markNotificationAsRead(notificationId);
    newSnack("error", t('NotificationEach12'));
  }
};

// Community詳細への遷移関数
const toCommunitypage = async (pageId, notificationId) => {
  try {
    if (pageId) {
      await markNotificationAsRead(notificationId);
      getNotification();
      history.push(`/communitypage/${pageId}`);
    } else {
      throw new Error("Page not found");
    }
  } catch (error) {
    await markNotificationAsRead(notificationId);
    newSnack("error", t('NotificationEach12'));
  }
};

// ユーザーBookへの遷移関数
const toSpecificUserPage = async (userId, notificationId) => {
  try {
    await markNotificationAsRead(notificationId);

    if (userId === profile.userProfile) {
      getNotification();
      history.push("/userpage");
    } else if (userId) {
      history.push(`/user/${userId}`);
    } else {
      throw new Error("User not found");
    }
  } catch (error) {
    await markNotificationAsRead(notificationId);
    newSnack("error", t('NotificationEach13'));
  }
};
  const { t } = useTranslation();
  const { newSnack } = useContext(SnackbarContext);
  const history = useHistory();

  const containerClass = notiData.read
  ? "card bg-transparent-card"
  : "card bg-lightgreen notification-unread";




  const renderNotificationContent = () => {
    if (!notiData) return null; // notiDataがnullの場合にエラーを防ぐ
    switch (notiType) {
      case 'post-commented':
        return (
          <>
          {
            !notiData.post.purepost_flag ?

            <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
            <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img:  process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"}  alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.post?.text && notiData.post.text.length > 10 
    ? notiData.post?.text.substring(0, 15) + "..." 
    : notiData.post?.text}   <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
              </h5>
              <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach1')}</h6>
            </div>
            :
            <div onClick={() => toSpecificUserPage(profile.userProfile, notiData.id)}>
            <img src={notiData.relatedUser?.img ?(notiData.relatedUser.img.slice(0, 4) === "http" ? notiData.relatedUser.img: process.env.REACT_APP_API_URL + notiData.relatedUser.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-circle" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.post?.text && notiData.post.text.length > 10 
    ? notiData.post?.text.substring(0, 15) + "..." 
    : notiData.post?.text} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
              </h5>
              <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach1')}</h6>
            </div>
          }
          </>
        );
      case 'post-liked':
        return (
          <>
          {
            !notiData.post.purepost_flag ?
          <>
          <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
          <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
          <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.post?.text && notiData.post.text.length > 10 
    ? notiData.post?.text.substring(0, 15) + "..." 
    : notiData.post?.text} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach2')}</h6>
          </div></>:
          <>
          <div onClick={() => toSpecificUserPage(profile.userProfile, notiData.id)}>
            <img src={notiData.relatedUser?.img ?(notiData.relatedUser.img.slice(0, 4) === "http" ? notiData.relatedUser.img: process.env.REACT_APP_API_URL +  notiData.relatedUser.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-circle" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.post?.text && notiData.post.text.length > 10 
    ? notiData.post?.text.substring(0, 15) + "..." 
    : notiData.post?.text}  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach2')}</h6>
          </div></>
        }</>);

      case 'post-reposted':
        return (
          <>
          {!notiData.post.purepost_flag ?
            <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
            <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.post?.text && notiData.post.text.length > 10 
    ? notiData.post?.text.substring(0, 15) + "..." 
    : notiData.post?.text} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
              </h5>
              <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach3')}</h6>
            </div>:
            <div onClick={() => toSpecificUserPage(notiData.relatedUser.userProfile, notiData.id)}>
            <img src={notiData.relatedUser?.img ?(notiData.relatedUser.img.slice(0, 4) === "http" ? notiData.relatedUser.img: process.env.REACT_APP_API_URL + notiData.relatedUser.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-circle" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.post?.text && notiData.post.text.length > 10 
    ? notiData.post?.text.substring(0, 15) + "..." 
    : notiData.post?.text} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
              </h5>
              <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach3')}</h6>
            </div>
          }
          </>
        );
      case 'comment-commented':
        return (
          <>
          {
            !notiData.post.purepost_flag ?
            <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
            <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.comment?.text && notiData.comment.text.length > 10 
    ? notiData.comment?.text.substring(0, 15) + "..." 
    : notiData.comment?.text}  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
              </h5>
              <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach4')}</h6>
            </div>
            :
            <div onClick={() => toSpecificUserPage(notiData.post.userPost.id, notiData.id)}>
            <img src={notiData.relatedUser?.img ?(notiData.relatedUser.img.slice(0, 4) === "http" ? notiData.relatedUser.img: process.env.REACT_APP_API_URL + notiData.relatedUser.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-circle" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.comment?.text && notiData.comment.text.length > 10 
    ? notiData.comment?.text.substring(0, 15) + "..." 
    : notiData.comment?.text}  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
              </h5>
              <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach4')}</h6>
            </div>

          }
          </>
        );
      case 'followingpage-commented':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
          <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
          <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
            {notiData.post?.text && notiData.post.text.length > 10 
    ? notiData.post?.text.substring(0, 15) + "..." 
    : notiData.post?.text}  <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach5')}</h6>
          </div></>
        );
      case 'data-generated':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
          <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.page.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach6')}</h6>
          </div></>
        );
      case 'data-book-owned':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
          <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.page?.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach7')}</h6>
          </div></>
        );
      case 'data-book-followed':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
          <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.page?.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach8')}</h6>
          </div></>
        );
      case 'book-posted':
        return (
          <>
          <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
          <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.page?.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach11')}</h6>
          </div></>
        );
      case 'user-followed':
        return (
          <>
          <div onClick={() => toSpecificUserPage(notiData.relatedUser.userProfile, notiData.id)}>
          <img src={notiData.relatedUser?.img ?(notiData.relatedUser.img.slice(0, 4) === "http" ? notiData.relatedUser.img: process.env.REACT_APP_API_URL + notiData.relatedUser.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-circle" />
            <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
              {notiData.relatedUser?.nickName} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach9')}</h6>
          </div></>
        );
        case 'followinguser-book-generated':
          return (
            <>
            <div onClick={() => toMonopage(notiData.page?.id, notiData.id)}>
            <img src={notiData.page?.img ?(notiData.page.img.slice(0, 4) === "http" ? notiData.page.img: process.env.REACT_APP_API_URL + notiData.page.img): "assets/images/user.png"} alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
              <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
                {notiData.page?.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
              </h5>
              <h6 className="text-grey-500 fw-500 font-xssss lh-4">{t('NotificationEach10')}</h6>
            </div></>
          );
          case 'community-invited':
            return (
              <>
              <div onClick={() => toCommunitypage(notiData.community?.id, notiData.id)}>
              <img src={notiData.community?.image ?(notiData.community.image.slice(0, 4) === "http" ? notiData.community.image: process.env.REACT_APP_API_URL + notiData.community.image): "assets/images/user.png"}
      alt="user" className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3" />
                <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
                  {notiData.community?.title} <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> {formatDate(notiData.created_on)}</span>
                </h5>
                <h6 className="text-grey-500 fw-500 font-xssss lh-4">サークルに招待されました</h6>
              </div></>
            );
            case 'community-joinrequest-approved':
              return (
                <>
                  <div onClick={() => toCommunitypage(notiData.community?.id, notiData.id)}>
                    <img
                      src={notiData.community?.image ?(notiData.community.image.slice(0, 4) === "http" ? notiData.community.image: process.env.REACT_APP_API_URL + notiData.community.image): "assets/images/user.png"}
                      alt="user"
                      className="w40 position-absolute mt-1 left-0 shadow-sm rounded-3"
                    />
                    <h5 className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block">
                      {notiData.community?.title || "コミュニティ参加申請承認"}
                      <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                        {formatDate(notiData.created_on)}
                      </span>
                    </h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">参加申請が承諾されました</h6>
                  </div>
                </>
              );
            
            
      default:
        return null;
    }
  };

  return (
    <div className={`${containerClass} w-100 border-0 ps-5 mb-3`}>
      
      {renderNotificationContent()}
    </div>
  );
};

export default NotificationEach;
