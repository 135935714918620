import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Tooltip, Fab } from "@material-ui/core";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { ApiContext } from "../context/ApiContext";
import { green } from "@material-ui/core/colors";
import Lightbox from "react-image-lightbox";
import SpecificUserpagePicture from "../components/SpecificUserpagePicture";
import SpecificUserpageEach from "../components/SpecificUserpageEach";
import SpecificUserProfiledetail from "../components/SpecificUserProfileDetail";
import AdvertisementView from "../components/AdvertisementView";
import NoPostYetforSpecificUser from "../components/NoPostYetforSpecificUser";
import Postview from "./Postview";
import { useTranslation } from 'react-i18next';
import JoinRequestButton from "../components/JoinRequestButton";
import ApproveJoinRequestButton from "../components/ApproveJoinRequestButton";
import axios from "axios";
import { SnackbarContext } from "../context/SnackbarContext";
import { AddComment } from "@material-ui/icons";
import DataModal from "../components/DataModal";
import { makeStyles } from "@material-ui/core/styles";
import Createpurepost from "../components/CreatePurepost";
import CreateCommunityPost from "../components/CreateCommunityPost";
import CommunityInviteModal from "../components/CommunityInviteModal";
import { Helmet } from "react-helmet";



const useStyles = makeStyles((theme) => {
  return {
    PostButton: {
      backgroundColor: green[400],
      "&:hover": {
        backgroundColor: green[200],
      }
    },
    PostButtonContainer: {
      position: "fixed",
      width: "100%",
      zIndex: "999",
      left: "350px",
      bottom: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('xs')]: { 
        left: '150px',
      },
    },
    DataButton: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      },
    DataButtonContainer: {
        position: "fixed",
        width: "100%",
        zIndex: "999",
        left: "350px",
        bottom: "135px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          left: '150px',
        },
    },
  };
});

const CommunityPage = ( ) => {
  const [communityData, setCommunityData] = useState(null);
  const [joinRequests, setJoinRequests] = useState([]);
  const { profile, ads, userpostWithScroll, fetchCommunityPostsWithScroll, newUserIntuser, randomAds, fetchRandomAds } = useContext(ApiContext);
  const { t } = useTranslation();
  const { newSnack } = useContext(SnackbarContext);
  const { communityid } = useParams();
  const [token] = useState(localStorage.getItem('current-token'));
  const [isOpenModal, setIsOpenModal] = useState(false);
  const classes = useStyles();
  const [isShowDataModal, setShowDataModal] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isShowCommunityInviteModal, setShowCommunityInviteModal] = useState(false);

  const handleDataClick = () => {
    if (!profile.userProfile) {
      newSnack("error", t('Header14'));
      return;
    }
    setShowDataModal(true);
  }

  const handleDataModalClose = () => {
    setShowDataModal(false);
  }

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const [communityStatus, setCommunityStatus] = useState("none");

  // communityStatus を取得する関数
  const fetchCommunityStatus = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/community_status/${communityid}/`,
        {
          headers: token ? { Authorization: `Token ${token}` } : {},
        }
      );
      // レスポンス全体を state に保存する
      setCommunityStatus(res.data);
    } catch (error) {
      console.error("コミュニティステータス取得エラー:", error);
    }
  };
  

  useEffect(() => {
    // ログインしていない場合は状態の更新は行わなくてもよい
    if (profile && profile.id) {
      fetchCommunityStatus();
    }
  }, [communityid, profile]);


// CommunityPage.jsx の該当部分

useEffect(() => {
  // ログインしていない場合は state の更新は不要
  if (profile && profile.id) {
    fetchCommunityStatus();
  }
}, [communityid, profile]);

// ボタンの表示とクリック処理の設定
let joinButtonText = "参加申請";
let joinButtonDisabled = false;
let joinButtonOnClick = () => {
  if (!profile.id) {
    newSnack("error", "ログインしてください");
    return;
  }
  // 状態が "none" なら join request の作成
  createJoinRequest(communityid);
};

if (communityStatus.status === "member") {
  joinButtonText = "参加済み";
  joinButtonDisabled = true;
  joinButtonOnClick = null;
} else if (communityStatus.status === "join_request_pending") {
  joinButtonText = "参加申請中";
  joinButtonDisabled = true;
  joinButtonOnClick = null;
} else if (communityStatus.status === "invitation_pending") {
  joinButtonText = "招待を受諾して参加";
  joinButtonDisabled = false;
  joinButtonOnClick = () => {
    acceptInvitation(communityStatus.invitation_id);
  };
}


// acceptInvitation 関数
const acceptInvitation = async (invitationId) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/invitation/${invitationId}/accept/`,
      {},
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    newSnack("success", "招待が承認され、参加しました。");
    // 必要なら communityStatus を更新するなどの処理
  } catch (error) {
    newSnack("error", "招待の承認に失敗しました。");
  }
};


const createJoinRequest = async (communityid) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/communityjoin/`,
			{ community: communityid }, // POSTデータに community のID を含める
			{ headers: { Authorization: `Token ${token}` } }
    );
    newSnack("success", "参加申請が送信されました。");
    // 必要なら communityStatus を更新（例えば "join_request_pending" にする）
  } catch (error) {
    newSnack("error", "参加申請の送信に失敗しました。");
  }
};


// 例: disabled な状態のテキストの場合
const disabledStatuses = ["参加済み", "参加申請中"];
const buttonStyleClasses = disabledStatuses.includes(joinButtonText)
  ? "bg-grey text-white"
  : "bg-green-gradiant text-white pointer";





  // コミュニティ情報の取得（例）
  useEffect(() => {
    const fetchCommunity = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/community/${communityid}/`,
          { headers: { Authorization: `Token ${token}` } }
        );
        setCommunityData(res.data);
      } catch (error) {
        console.error("コミュニティ情報の取得エラー", error);
      }
    };
    fetchCommunity();
    fetchRandomAds();
  }, [communityid, token]);



  const [count, setCount] = useState(0);
  const [postsbyscroll, setPostsbyscroll] = useState([]);
  const loaderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // 新しい関数を使用して、community に紐づく投稿を取得
  const fetchData = async () => {
    setIsLoading(true);
    const result = await fetchCommunityPostsWithScroll(communityid, count, token);
    if (result && result.res) {
      setPostsbyscroll(prevItems => [...prevItems, ...result.res]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [count, communityid]);

  const handleObserver = (entities, observer) => {
    const target = entities[0];

    if (target.isIntersecting && !isLoading) {
      setCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleInviteClick = () => {
    setShowCommunityInviteModal(true);
  };

  useEffect(() => {
    // ログイン状態（profile.id が存在する場合）のみログを記録
    if (profile && profile.id) {
      axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/access-log/`,
        { type: "community", community_id: communityid },
        { headers: { Authorization: `Token ${token}` } }
      ).catch((err) => {
        console.error("Access log error:", err);
      });
    }
  }, [communityid, profile, token]);
  

  return (

    <Fragment> 
            <Helmet>
        <title>
          {communityData && communityData.title
            ? `${communityData.title} | Tatami`
            : "Tatami | サークル"}
        </title>
        <meta
          name="description"
          content={
            communityData && communityData.description
              ? communityData.description
              : "Tatamiのサークルページ。経済、ガジェット、スポーツ、先端科学技術など、多彩な情報が集まるTatamiで、サークルの情報を確認できます。"
          }
        />
        <meta
          property="og:title"
          content={
            communityData && communityData.title
              ? `${communityData.title} | Tatami`
              : "Tatami | サークル"
          }
        />
        <meta
          property="og:description"
          content={
            communityData && communityData.description
              ? communityData.description
              : "Tatamiのサークルページ。経済、ガジェット、スポーツ、先端科学技術など、多彩な情報が集まるTatamiで、サークルの情報を確認できます。"
          }
        />
        <meta
          property="og:image"
          content={
            communityData && communityData.image
              ? (communityData.image.startsWith("http")
                  ? communityData.image
                  : `${process.env.REACT_APP_API_URL}${communityData.image}`)
              : "https://tatami.site/default_community_image.jpg"
          }
        />
        <meta property="og:url" content={`https://tatami.site/community/${communityid}`} />
        <meta
          property="twitter:title"
          content={
            communityData && communityData.title
              ? `${communityData.title} | Tatami`
              : "Tatami | サークル"
          }
        />
        <meta
          property="twitter:description"
          content={
            communityData && communityData.description
              ? communityData.description
              : "Tatamiのサークルページ。経済、ガジェット、スポーツ、先端科学技術など、多彩な情報が集まるTatamiで、サークルの情報を確認できます。"
          }
        />
        <meta
          property="twitter:image"
          content={
            communityData && communityData.image
              ? (communityData.image.startsWith("http")
                  ? communityData.image
                  : `${process.env.REACT_APP_API_URL}${communityData.image}`)
              : "https://tatami.site/default_community_image.jpg"
          }
        />
        <meta property="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": communityData && communityData.title
              ? communityData.title
              : "Tatami サークル",
            "description": communityData && communityData.description
              ? communityData.description
              : "Tatamiのサークルページ。",
            "image": communityData && communityData.image
              ? (communityData.image.startsWith("http")
                  ? communityData.image
                  : `${process.env.REACT_APP_API_URL}${communityData.image}`)
              : "https://tatami.site/default_community_image.jpg",
            "url": `https://tatami.site/community/${communityid}`
          })}
        </script>
      </Helmet>
    <Header />
    <Leftnav />

    <div className="main-content right-chat-active">

    <DataModal
        isVisible={isShowDataModal}
        handleClose={handleDataModalClose}
        
      />
        <CreateCommunityPost
      isVisible={isOpen}
      profileData={profile}
      handleClose={closeDialog}
      communityId={communityid}
    />
            <CommunityInviteModal
          isVisible={isShowCommunityInviteModal}
          handleClose={() => setShowCommunityInviteModal(false)}
        />
        <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
      
            <div className={ classes.PostButtonContainer }>
                <Tooltip title="Post Comment" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={openDialog}
                    >
                      <AddComment />
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={()=>newSnack("error", t('Home3'))}
                    >
                      <AddComment />
                    </Fab>
                  )}
                </Tooltip>
              </div>
              <div className={ classes.DataButtonContainer }>
                <Tooltip title="Post Data" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={handleDataClick}
                    >

                    <i className="feather-file-plus font-xl text-white"></i>

                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={()=>newSnack("error", "投稿するにはログインしてください")}
                    >
                    <i className="feather-file-plus font-xl text-white"></i>
                    </Fab>
                  )}
                </Tooltip>
              </div>
                <div className="row">

    {communityData? (
                          <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                          <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3 pb-3">
          <div>
            {isOpenModal && (
              <Lightbox
                mainSrc={communityData.image.slice(0,4)==="http" ? communityData.image :process.env.REACT_APP_API_URL + communityData.image}
                onCloseRequest={() => setIsOpenModal(false)}
              />
            )}
              <div className="card-body position-relative h150 bg-image-cover bg-image-center"
                style={{backgroundImage: `url(${communityData.background_image.slice(0,4)==="http" ? communityData.background_image :process.env.REACT_APP_API_URL + communityData.background_image}`}} 
                ></div>
                <div  className="card-body d-block pt-4 text-center">
                    <figure onClick={() => setIsOpenModal(true)}  className="  avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                      <img  src={communityData.image.slice(0,4)==="http" ? communityData.image :process.env.REACT_APP_API_URL + communityData.image} alt="avater" className="p-1 bg-white rounded-xl w-100 border-white border-1 card" /></figure>
                    <h4 className="font-xs ls-1 fw-700 text-grey-900">{communityData.title?communityData.title:""} </h4>
                </div>
            <div >

            </div>
          </div>

          <div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">
          <Link to={`/communitypagebook/${communityid}`} >
  <h4 className="font-xsssss pointer text-center text-grey-500 fw-600 ms-2 me-2">
    <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
      {communityData.page_count}
    </b> Books
  </h4>
  </Link>
  <Link to={`/communitypagemember/${communityid}`} >
  <h4 className="font-xsssss pointer text-center text-grey-500 fw-600 ms-2 me-2">
    <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
      {communityData.member_count}
    </b> Members
  </h4>
  </Link>
  <Link to={`/communitypage/${communityid}`} >
  <h4 className="font-xsssss pointer text-center text-grey-500 fw-600 ms-2 me-2">
    <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
      {communityData.post_count}
    </b> Posts
  </h4>
  </Link>
  <Link to={`/communitypagewaiting/${communityid}`} >
  <h4 className="font-xsssss pointer  text-center text-grey-500 fw-600 ms-2 me-2">
    <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
      {communityData.joinrequest_count}
    </b> Waiting
  </h4>
  </Link>
</div>


<div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">

<span
  disabled={joinButtonDisabled}
  onClick={joinButtonOnClick}
  className={`join-button font-xsssss me-2 pe-3 ps-3 z-index-1 rounded-3 w100 text-center text-uppercase fw-700 ls-3 ${buttonStyleClasses}`}
  style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50px" }}
>
  {joinButtonText}
</span>

<a onClick={() => handleInviteClick()} className="me-2 bg-success p-3 w100 text-center pointer z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3 border-">招待</a>
{/* <a href="/defaultmember" className="bg-success p-3 w100 text-center  z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">Tatamiユーザー以外を招待</a> */}
{/* <a href="/defaultemailbox" className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"><i className="feather-mail font-md"></i></a>
<a href="/home" className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"><i className="ti-more font-md"></i></a> */}
</div>
</div>
<div style={{ position: 'relative' }}>
  {/* ぼかしなしの About セクション */}
  <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
    <div className="card-body d-block p-4">
      <h4 className="fw-700 mb-3 font-xsss text-grey-900">About</h4>
      <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
        {communityData.description}
      </p>
    </div>
  </div>
  
  {/* ぼかし対象のリンク部分 */}
  <div style={{ filter: communityStatus?.status !== "member" ? 'blur(5px)' : 'none' }}>
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <Link className="card-body border-top-xs d-flex pointer" to={`/communitypagebook/${communityid}`}>
        <i className="feather-book text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          サークルの本棚{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            サークルに投稿されたBookが確認できます
          </span>
        </h4>
      </Link>
      <Link className="card-body d-flex pt-0 pointer" to={`/communitypagemember/${communityid}`}>
        <i className="feather-users text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          メンバー{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            参加者の一覧を確認できます
          </span>
        </h4>
      </Link>
      <Link className="card-body d-flex pt-0 pointer" to={`/communitypagewaiting/${communityid}`}>
        <i className="feather-user-plus text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          参加申請{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            承認待ちのユーザーを確認できます
          </span>
        </h4>
      </Link>
    </div>
  </div>
  
  {/* メンバーでなければオーバーレイで案内を表示 */}
  {communityStatus?.status !== "member" && (
    <div
      style={{
        position: 'absolute',
        top: '70%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 20px',
        width: '85%',
        borderRadius: '5px',
        textAlign: 'center',
      }}
      className="font-xsss fw-600 text-grey-600 bg-transparent"
    >
      サークルのメンバーになると、投稿したり本棚を見ることができます
    </div>
  )}
</div>


</div>
        ) : (
          <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
          <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3 pb-3">
          <div>
          {isOpenModal && (
            <Lightbox

              mainSrc={communityData.image.slice(0,4)==="http" ? communityData.image :process.env.REACT_APP_API_URL + communityData.image}
              onCloseRequest={() => setIsOpenModal(false)}
            />
          )}
            {/* <div className="card-body position-relative h150 bg-image-cover bg-image-center"
              style={{backgroundImage: `url("https://via.placeholder.com/1200x250.png")`}}></div> */}
              <div onClick={() => setIsOpenModal(true)} className="card-body d-block pt-4 text-center">
              <figure
              className="avatar position-absolute w100 z-index-1"
              style={{ top: "-40px", left: "30px" }}
            >
              <img
               
                alt="avater"
                className="float-right p-1 bg-white rounded-circle w-100"
              />
            </figure>
                  <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto"><img  src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg" alt="avater" className="p-1 bg-white rounded-xl w-100" /></figure>
                  <h4 className="font-xs ls-1 fw-700 text-grey-900">読込中 <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">@surfiyazakir22</span></h4>
              </div>
          <div >

          </div>
        </div>
</div>
</div>
        )}

                    <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
                {postsbyscroll &&
                  postsbyscroll.map((post, index) => (
                    <Fragment key={index}>
                      <Postview
                        postData={post}
                        profileData={post.userPostProfile}
                        reposting={post.reposting}
                        repostingProfileData={post.repostingProfile || null}
                        commentedCounts={post.commentedCounts}
                        repostedTimes={post.repostedTimes}
                        likedTimes={post.likedTimes}
                        monopage = {post.reviewToPage}  
                      />
                  { index % 3 === 2 && randomAds.length > 0 ? (
                    <AdvertisementView
                      key={`ad-${index}`}
                      item={randomAds[parseInt(index / 3) % randomAds.length]}
                    />
                  ) : null}
                    </Fragment>
                  ))}
                                  <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }} className="mb-5"></div>
                {isLoading && (
                  <div className="card-body p-0 mb-3">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                      <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
{!profile.id ?
                          <div className="text-center p-4 text-grey-600 fw-600">
                          ログインしてください
                        </div>:
                        (!communityStatus?.status ==="member"?
                        <div className="text-center p-4 text-grey-600 fw-600">
                          サークルに参加すると投稿を確認できます
                        </div>:

                        (!isLoading && postsbyscroll.length === 0?
                          <NoPostYetforSpecificUser />
                          :<></>))
                          }

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div>
    </div>


    <Popupchat />
    <Appfooter /> 

</Fragment>

  );
};

export default CommunityPage;
