import React, { useState, useCallback, useContext, useRef } from "react";
import { Dialog, DialogContent, Button, TextField } from "@material-ui/core";
import axios from "axios";
import { SnackbarContext } from "../context/SnackbarContext";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "3em",
    height: "3em",
    border: "0.25em solid rgba(0,0,0,0.1)",
    borderTop: "0.25em solid currentColor",
    borderRadius: "50%",
    animation: "$spin 1s linear infinite",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10,
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
  dialogContent: {
    position: "relative",
  },
}));

// ランダムな背景画像のFileオブジェクトを生成する関数
const getRandomBackgroundFile = async () => {
  const randomNumber = Math.floor(Math.random() * 59) + 1;
  // publicフォルダは process.env.PUBLIC_URL で参照可能
  const backgroundUrl = `${process.env.PUBLIC_URL}/assets/images/community_default_image/background_${randomNumber}.jpeg`;
  const response = await fetch(backgroundUrl);
  const blob = await response.blob();
  return new File([blob], `background_${randomNumber}.jpeg`, { type: blob.type });
};

const CreateCommunity = (props) => {
  const { newSnack } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const [communityImage, setCommunityImage] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const [token] = useState(localStorage.getItem('current-token'));

  // 画像圧縮＋クロップ関数（1:1比率にクロップ）
  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const outputSize = 400; // 出力サイズを固定
          const canvas = document.createElement("canvas");
          canvas.width = outputSize;
          canvas.height = outputSize;
          const ctx = canvas.getContext("2d");
  
          // オリジナル画像の中央部分を切り出し、正方形にする
          const minDimension = Math.min(img.width, img.height);
          const sx = (img.width - minDimension) / 2;
          const sy = (img.height - minDimension) / 2;
  
          // 正方形の部分を400x400に描画
          ctx.drawImage(img, sx, sy, minDimension, minDimension, 0, 0, outputSize, outputSize);
  
          let quality = 0.9;
          const compressRecursive = (quality) => {
            // "image/jpeg" を固定で指定してみる
            canvas.toBlob(
              (blob) => {
                if (!blob) {
                  reject(new Error("Compression failed"));
                  return;
                }
                if (blob.size <= 600 * 1024 || quality <= 0.1) {
                  const compressedFile = new File([blob], file.name, { type: "image/jpeg" });
                  resolve(compressedFile);
                } else {
                  compressRecursive(quality - 0.1);
                }
              },
              "image/jpeg",
              quality
            );
          };
          compressRecursive(quality);
        };
        img.onerror = (error) => reject(error);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  
  

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setIsImageLoading(true);
      let file = e.target.files[0];
      try {
        // 常にクロップ＆圧縮処理を実行する
        file = await compressImage(file);
      } catch (error) {
        console.error("Compression error:", error);
        setIsImageLoading(false);
        return;
      }
      const previewUrl = URL.createObjectURL(file);
      setImgSrc(previewUrl);
      setCommunityImage(file);
      setIsImageLoading(false);
    }
  };
  

  const handleSubmit = async () => {
    setIsImageLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (communityImage) {
      formData.append("image", communityImage, communityImage.name);
    }
    // ランダムに背景画像ファイルを取得して追加
    try {
      const backgroundFile = await getRandomBackgroundFile();
      formData.append("background_image", backgroundFile, backgroundFile.name);
    } catch (error) {
      console.error("Error fetching background image:", error);
    }

    try {
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "/api/user/create-community/",
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      props.onNewCommunity(res.data);
      newSnack("success", "サークルを作成しました");
      handleClose();
    } catch (error) {
      console.error("サークル作成失敗:", error);
      newSnack("error", "エラーが発生しました");
    } finally {
      setIsImageLoading(false);
    }
  };


  const handleClose = () => {
    setTitle("");
    setDescription("");
    setImgSrc("");
    setCommunityImage(null);
    props.onClose();
  };

  return (
    <Dialog open={props.isVisible} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogContent className={classes.dialogContent}>
        <TextField
          label="サークル名"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          label="説明"
          fullWidth
          multiline
          rows={4}
          inputProps={{ maxLength: 2000 }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginTop: 16 }}
        />
        {imgSrc && (
          <img
            src={imgSrc}
            alt="Community"
            style={{
              width: "100%",
              aspectRatio: "1/1",
              objectFit: "cover",
              marginTop: 16,
            }}
          />
        )}
        <div className="d-flex" style={{ marginTop: 16, position: "relative", justifyContent: "center", alignItems: "center", gap: "0.3rem" }}>
          <div>
            <Button variant="contained" component="label" className="bg-current text-center text-white font-xssss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1">
              画像アップロード
              <input type="file" hidden onChange={onFileChange} />
            </Button>
            {isImageLoading && <span className={classes.spinner} style={{ color: "#79ac78" }} />}
          </div>
          <div>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className={`text-center text-white font-xssss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1 ${title && description && communityImage ? "bg-current" : ""}`}
              disabled={!(title && description && communityImage)}
            >
              サークル作成
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCommunity;
