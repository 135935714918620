import React, { Fragment, useState, useEffect, useRef, useCallback, useContext } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import RecommenduserEach from "../components/RecommenduserEach";
import LoginComponent from "../components/LoginComponent";
import ProfileRecommender from "../components/ProfileRecommender";
import HowToModal from "../components/HowToModal";
import { ApiContext } from "../context/ApiContext";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Fab, Tooltip } from "@material-ui/core";
import { SnackbarContext } from "../context/SnackbarContext";
import CreateCommunity from "../components/CreateCommunity";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "block",
    position: "relative",
    "&::after": {
      background: "linear-gradient(to right,#79ac78 0%,#79ac78 50%,#79ac78 100%)",
      content: '""',
      display: "block",
      height: "2px",
      width: 0,
      transform: "translateX(-50%)",
      transition: "2s cubic-bezier(0.22, 1, 0.36, 1)",
      position: "absolute",
      bottom: 0,
      left: "50%",
    },
    "&.is-animated::after": {
      width: "100%",
    },
  },
  DataButtonContainer: {
    position: "fixed",
    width: "100%",
    zIndex: 999,
    left: "350px",
    bottom: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      left: "150px",
    },
  },
  DataButton: {
    background: "linear-gradient(-45deg,#79ac78,#B0D9B1)",
    backgroundSize: "400% 400%",
    animation: "$gradient 10s ease infinite",
  },
  "@keyframes gradient": {
    "0%": { backgroundPosition: "0% 50%" },
    "50%": { backgroundPosition: "100% 50%" },
    "100%": { backgroundPosition: "0% 50%" },
  },
}));

const Recommenduser = () => {
  const { profile, profilesWithScroll, searchProfiles, loggedInProfilesWithScroll } = useContext(ApiContext);
  const { newSnack } = useContext(SnackbarContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const [profilesbyscroll, setProfilesbyscroll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [noMoreData, setNoMoreData] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showProfiles, setShowProfiles] = useState([]);
  const [animate, setAnimate] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const loaderRef = useRef(null);
  // isMountedRef を用意して、コンポーネントがマウントされているかを追跡
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const [isShowHowToModal, setShowHowToModal] = useState(false);
  const [isShowCreateCommunity, setShowCreateCommunity] = useState(false);
  const token = localStorage.getItem("current-token");

  // IntersectionObserver のハンドラーを useCallback でラップする
  const handleObserver = useCallback((entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading && !noMoreData) {
      setCount((prev) => prev + 1);
    }
  }, [isLoading, noMoreData]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    // 検索はエンター送信まで待つ
  };

  const fetchData = async () => {
    setIsLoading(true);
    let result;
    if (token) {
      // ログインしている場合
      result = await loggedInProfilesWithScroll(count);
    } else {
      // 未ログインの場合
      result = await profilesWithScroll(count);
    }
    
    if (isMountedRef.current) {
      if (!result || result.res.length === 0) {
        setNoMoreData(true);
      } else {
        setProfilesbyscroll((prevItems) => [...prevItems, ...result.res]);
      }
      setIsLoading(false);
    }
  };

  

  useEffect(() => {
    if (searchTerm !== "") {
      if (count === 0) setShowProfiles([]);
      searchProfiles(searchTerm, count).then((result) => {
        if (isMountedRef.current) {
          if (count === 0) setShowProfiles(result.res);
          else setShowProfiles((prev) => [...prev, ...result.res]);
        }
      });
    } else {
      if (count === 0) setProfilesbyscroll([]);
      fetchData();
    }
  },  [count, searchTerm]);



  useEffect(() => {
    setAnimate(true);
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [handleObserver]);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    setCount(0);
    setProfilesbyscroll([]);
    if (!hasVisited) {
      setShowHowToModal(true);
      localStorage.setItem("hasVisited", "true");
    }
  }, []);

  const defaultProfiles = profilesbyscroll.map((profileItem, index) => (
    <RecommenduserEach key={index} profileeach={profileItem} />
  ));
  const listProfiles = showProfiles.map((profileItem) => (
    <RecommenduserEach key={profileItem.id} profileeach={profileItem} />
  ));

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const trimmed = inputValue.trim();
    if (trimmed === "") {
      setCount(0);
      setProfilesbyscroll([]);
      setShowProfiles([]);
      setSearchTerm("");
    } else {
      setCount(0);
      setShowProfiles([]);
      setSearchTerm(trimmed);
    }
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      {/* CreateCommunity ボタン */}
      <div className={classes.DataButtonContainer}>
        <Tooltip title="Create Community" placement="top-start">
          {profile.id ? (
            <Fab
              color="secondary"
              className={classes.DataButton}
              onClick={() => setShowCreateCommunity(true)}
            >
              <i className="feather-users font-xl text-white"></i>
            </Fab>
          ) : (
            <Fab
              color="secondary"
              className={classes.DataButton}
              onClick={() =>
                newSnack("error", "コミュニティ作成するにはログインしてください")
              }
            >
              <i className="feather-users font-xl text-white"></i>
            </Fab>
          )}
        </Tooltip>
      </div>
      <div className="main-content right-chat-active">
        <HowToModal isVisible={isShowHowToModal} handleClose={() => setShowHowToModal(false)} />
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                    {t("Recommenduser4")}
                    <form className="pt-0 pb-0 ms-auto" onSubmit={handleSearchSubmit}>
                      <div className="search-form-2 ms-1">
                        <i className="ti-search font-xss"></i>
                        <input
                          type="text"
                          value={inputValue}
                          onChange={handleInputChange}
                          className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                          placeholder={t("Recommenduser3")}
                        />
                      </div>
                    </form>
                  </h2>
                </div>
                {inputValue ? (
                  <div
                    className={`${classes.line} ${
                      animate ? "is-animated" : ""
                    } font-xssss text-center text-current fw-600 mt-0 mb-2`}
                  >
                    {t("Recommenduser1")}
                  </div>
                ) : (
                  <div
                    className={`${classes.line} ${
                      animate ? "is-animated" : ""
                    } font-xssss text-center text-current fw-600 mt-0 mb-2`}
                  >
                    {t("Recommenduser2")}
                  </div>
                )}
                <div className="row ps-3 pe-3">
                  {searchTerm !== ""
                    ? showProfiles.length > 0
                      ? showProfiles.map((profileItem) => (
                          <RecommenduserEach key={profileItem.id} profileeach={profileItem} />
                        ))
                      : <div>No search results</div>
                    : defaultProfiles}
                </div>
                <div ref={loaderRef} style={{ height: "20px", margin: "0 auto" }}></div>
                {isLoading && (
                  <div className="card-body p-0 mb-3">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                      <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="p-4 mb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Appfooter />
      <CreateCommunity
        isVisible={isShowCreateCommunity}
        onClose={() => setShowCreateCommunity(false)}
        token={token}
        onNewCommunity={(data) => {
          console.log("New community created:", data);
        }}
      />
    </Fragment>
  );
};

export default Recommenduser;
