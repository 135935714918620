import React, { useContext,useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ApiContext } from "../context/ApiContext";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardActions, Grid } from "@material-ui/core";
import { SnackbarContext } from "../context/SnackbarContext";
import clsx from "clsx";

const useStyles = makeStyles({
  card: {
    maxWidth: 360,
    marginBottom: "1rem",
    height: 180, // カード全体の固定高さ
  },
  cardContent: {
    minHeight: "20px",
    overflow: "hidden", // はみ出た内容は隠す
  },
  media: {
    height: 140,
  },
});

export const CommunityPageWaitingEach = (profileeach, mutualFlag) => {
  const history = useHistory()
  const classes = useStyles();
  const { communityid } = useParams();
  const [token] = useState(localStorage.getItem("current-token"));
  const { profile, newRequestFriend, followinguser, deleteFriendRequest } = useContext(ApiContext);

  const { newSnack } = useContext(SnackbarContext);

    // 作成日を元に「New」表示
    const createdDate = new Date(profileeach.created_on || profileeach.createdon);
    const today = new Date();
    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(today.getDate() - 2);
    const isNew = createdDate > threeDaysAgo;

  

  const toSpecificUserPage = () => {
      if (profile.userProfile === profileeach.profileeach.userProfile) {
          console.log("本人");
          history.push("/userpage");
      } else {

          history.push(`/user/${profileeach.profileeach.userProfile}`);
      }
  };
  const { t } = useTranslation();

  const approveJoinRequest = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/community/${communityid}/join-request/approve/${profileeach.profileeach.userProfile}/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      newSnack("success", "ユーザーが参加しました。");
      // Optionally, update local UI state to reflect the change.
    } catch (error) {
      newSnack("error", "参加申請の承認に失敗しました。");
      console.error("Approve join request error:", error);
    }
  };




  return (
    <div className="col-md-3 col-sm-4 pe-2 ps-2">
      <Card className={clsx(classes.card, "card shadow-xss")}>
        {isNew && (
          <a
            className="fw-600 ms-auto font-xsssss text-primary ps-2 pe-2 pb-0 pt-0 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm position-absolute top---15 mb-5 ms-2 right-15"
          >
            New
          </a>
        )}

        <CardContent className={clsx(classes.cardContent, "text-center", "mycard")}>
            {profileeach.profileeach.img ? (
              <figure
                className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
                onClick={toSpecificUserPage}
              >
                <img
                  src={
                    profileeach.profileeach.img.slice(0, 4) === "http"
                      ? profileeach.profileeach.img
                      : process.env.REACT_APP_API_URL + profileeach.profileeach.img
                  }
                  alt="avatar"
                  className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                />
              </figure>
            ) : (
              <figure
                className="overflow-hidden avatar ms-auto me-auto mb-3 position-relative w65 z-index-1 pointer"
                onClick={toSpecificUserPage}
              >
                <img
                  src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                  alt="avatar"
                  className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                />
              </figure>
            
          )}

          <h4 className="fw-700 font-xsss mt-3 mb-0">
            {profileeach.profileeach.nickName}
          </h4>
          <h4 className="fw-700 font-xsssss text-grey-600 mt-2 mb-0">
</h4>
        </CardContent>

        <CardActions className="mycard">
          <Grid container justifyContent="center">
                      <Grid item>
                      <button
                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current pointer font-xsssss fw-700 ls-lg text-white"
                onClick={approveJoinRequest}
              >
                参加を承諾
              </button>
                      </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};
