import React, {
  useContext,
  Fragment,useState, useEffect 
  // useCallback, useState
} from "react";
import { ApiContext } from "../context/ApiContext";
import { Link } from "react-router-dom";
import {
  Button,
  // makeStyles
} from "@material-ui/core";
import { useCookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; 
import { SnackbarContext } from "../context/SnackbarContext";
import { NavbarBrand } from "react-bootstrap";

export const ASPECT_RATIO = 1 / 1;
export const CROP_WIDTH = 400;




const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: 30,
      minWidth: "100%",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      flexFlow: "column",
      "& .file-upload-container": {
        width: 500,
        marginTop: 10,
        "& .button": {
          backgroundColor: "#00A0FF",
          color: "white",
        },
      },
    },
    validationWrapper: {
      float: "right"
    }
  }
})
// console.log(i18n.language)
const NotificationSetting = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    profile,getUserNotificationSettings,updateUserNotificationSettings
    //  editedProfile, setEditedProfile, deleteProfile, setCover, coverBack, createProfile, editProfile
  } = useContext(ApiContext);

  const { newSnack } = useContext(SnackbarContext);

  const [cookies, setCookie, removeCookie] = useCookies(["current-token"]);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const fetchedSettings = await getUserNotificationSettings();
      setSettings(fetchedSettings[0]);
    };
    fetchSettings();
  }, []);

  const handleToggle = async (type) => {
    const updatedSettings = { ...settings, [type]: !settings[type] };
    const result = await updateUserNotificationSettings(updatedSettings);
    setSettings(result);
  };

  return (
    <Fragment>
    <Header />
    <Leftnav />
    <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                <Link to="/settings" className="d-inline-block mt-2">
                  <i className="ti-arrow-left font-sm text-white"></i>
                </Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                  {t('NotificationSetting1')}
                </h4>
              </div>
              <div className="card-body p-lg-5 p-4 w-100 border-0">
              <div className="row">
                    <div className="col-lg-12">
                      {/* {!cookies["current-token"] ? (
                        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 me-3 bg-current">
                          <LoginComponent />
                        </div>
                      ) : (
                        <></>
                      )} */}
                      {/* <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                      {t('NotificationSetting1')}
                      </h4> */}
                      <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                      {t('NotificationSetting2')}
                      </div>
                      <ul className="list-inline mb-4">

                          <li className="list-inline-item d-block border-bottom me-0">
                          <div
                            to="/account"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-primary-gradiant text-white feather-thumbs-up font-md me-3"></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting3')}
                            </h4>
                            {
                              settings&&settings.post_liked ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer "onClick={() => handleToggle('post_liked')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('post_liked')}></i>
                            }
                          </div>

                        </li>

                        <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-primary-gradiant text-white feather-message-square font-md me-3"></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting4')}
                            </h4>
                            {
                              settings&&settings.post_commented ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('post_commented')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('post_commented')}></i>
                            }
                          </div>

                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-primary-gradiant text-white feather-repeat font-md me-3"></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting5')}
                            </h4>
                            {
                              settings&&settings.post_reposted ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('post_reposted')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('post_reposted')}></i>
                            }
                          </div>

                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-primary-gradiant text-white feather-user font-md me-3"></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting13')}
                            </h4>
                            {
                              settings&&settings.user_followed ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('user_followed')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('user_followed')}></i>
                            }
                          </div>

                        </li>
                      </ul>


                      <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                      {t('NotificationSetting6')}
                      </div>

                      <ul className="list-inline mb-4">
                      <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-blue-gradiant text-white feather-book-open font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting7')}
                            </h4>
                            {
                              settings&&settings.data_generated ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('data_generated')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('data_generated')}></i>
                            }
                          </div>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-blue-gradiant text-white feather-message-square font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting15')}
                            </h4>
                            {
                              settings&&settings.book_posted ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('book_posted')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('book_posted')}></i>
                            }
                          </div>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-blue-gradiant text-white feather-user-plus font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting8')}
                            </h4>
                            {
                              settings&&settings.data_book_followed ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('data_book_followed')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('data_book_followed')}></i>
                            }
                          </div>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-blue-gradiant text-white feather-shopping-cart font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting9')}
                            </h4>
                            {
                              settings&&settings.data_book_owned ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('data_book_owned')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('data_book_owned')}></i>
                            }
                          </div>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-blue-gradiant text-white feather-activity font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            {t('NotificationSetting10')}
                            </h4>
                            {
                              settings&&settings.followingpage_commented ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('followingpage_commented')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('followingpage_commented')}></i>
                            }
                          </div>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-blue-gradiant text-white feather-map font-md me-3"></i>
                            <h4 className="fw-600 font-xssss mb-0 mt-0">
                            {t('NotificationSetting11')}
                            </h4>
                            {
                              settings&&settings.followinguser_book_generated ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('followinguser_book_generated')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('followinguser_book_generated')}></i>
                            }
                          </div>
                        </li>
                        
                      </ul>
                      <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                      サークル
                      </div>

                      <ul className="list-inline mb-4">
                      <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-green-gradiant text-white feather-user-plus font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            招待されたとき
                            </h4>
                            {
                              settings&&settings.community_invited  ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('community_invited')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('community_invited')}></i>
                            }
                          </div>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div

                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i className="btn-round-md bg-green-gradiant text-white feather-users font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                            申請が承諾されたとき
                            </h4>
                            {
                              settings&&settings.community_joinrequest_approved ?
                              <i className="feather-bell font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('community_joinrequest_approved')}></i>:
                              <i className="feather-bell-off font-xs text-grey-600 ms-auto mt-3 pointer"onClick={() => handleToggle('community_joinrequest_approved')}></i>
                            }
                          </div>
                        </li>

                        
                      </ul>

                      
                    </div>
                  </div>

            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <Appfooter />
  </Fragment>
  );
};

export default NotificationSetting;
