import React, { useState, useContext, useRef, useEffect } from "react";
import { ApiContext } from "../context/ApiContext";
import { Dialog, DialogContent, Button } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import { SnackbarContext } from "../context/SnackbarContext";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  line: {
    display: "block",
    position: "relative",
    "&::after": {
      background: "linear-gradient(to right,#79ac78 0%,#79ac78 50%,#79ac78 100%)",
      content: '""',
      display: "block",
      height: "1px",
      width: 0,
      transform: "translateX(-50%)",
      transition: "2s cubic-bezier(0.22, 1, 0.36, 1)",
      position: "absolute",
      bottom: 0,
      left: "50%",
    },
    "&.is-animated::after": {
      width: "100%",
    },
  },
  fileInputButton: {
    backgroundColor: green[400],
    color: 'white',
    '&:hover': {
      backgroundColor: green[200],
    },
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500'
  },
  spinner: {
    display: "inline-block",
    verticalAlign: "middle",
    color: "#666",
    lineHeight: 1,
    width: "6em",
    height: "6em",
    border: "0.12em solid rgba(102, 102, 102, 0.3)",
    borderTopColor: "currentColor",
    borderRadius: "50%",
    boxSizing: "border-box",
    animation: "$rotate 1s linear infinite",
    position: 'absolute',
    top: '30%',
    left: '40%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
  },
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  blurredContent: {
    filter: "blur(2px)",
    position: 'relative',
    zIndex: 1,
  },
}));

const DataModal = (props) => {
  const { profile } = useContext(ApiContext);
  const { newSnack } = useContext(SnackbarContext);
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const [animate, setAnimate] = useState(false);

  const [token] = useState(localStorage.getItem('current-token'));
  const [userCommunities, setUserCommunities] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);

  // URL の communityid を useParams で取得
  const { communityid } = useParams();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [isMyData, setIsMyData] = useState(false); // 初期値は false

  // file input 用の ref
  const fileInputRef = useRef(null);

  // データタイプ変更
  const handleDataTypeChange = (value) => {
    setIsMyData(value);
  };

  useEffect(() => {
    setAnimate(true);
  }, []);

  // ユーザーが所属するコミュニティ取得＆初期選択値の設定
  useEffect(() => {
    const fetchUserCommunities = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/mycommunities/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setUserCommunities(res.data);
        // URL に communityid が含まれている場合はそのコミュニティのみ選択
        if (communityid) {
          setSelectedCommunities([communityid.toString()]);
        } else {
          // それ以外は全て選択（従来の挙動）
          setSelectedCommunities(res.data.map((comm) => comm.id.toString()));
        }
      } catch (error) {
        console.error("Error fetching user communities:", error);
      }
    };
    if (props.isVisible) {
      fetchUserCommunities();
    }
  }, [props.isVisible, token, communityid]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const fileName = file.name.replace(/\s/g, "_");
    formData.append('file', new File([file], fileName));
    formData.append('description', description);
    formData.append('title', title);
    formData.append('user_id', profile.id);
    formData.append('price', priceLevel);
    formData.append('mydata', isMyData.toString());
    // 選択されたコミュニティIDを追加
    selectedCommunities.forEach((id) => {
      formData.append("communities", id);
    });
    
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + `/api/user/upload/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("File uploaded successfully", response.data);
      newSnack("success", t('DataModal16'));
      props.handleClose();
      setFile("");
      setDescription("");
      setTitle("");
    } catch (error) {
      newSnack("error", t('DataModal15'));
    } finally {
      setIsLoading(false);
    }
  };

  const [priceLevel, setPriceLevel] = useState("free");
  const priceLevels = [
    { key: "free", label: t('DataModal17'), label2: t('DataModal24') },
    { key: "low", label: t('DataModal18'), label2: t('DataModal25') },
    { key: "medium", label: t('DataModal19'), label2: t('DataModal26') },
    { key: "high", label: t('DataModal20'), label2: t('DataModal27') },
    { key: "great", label: t('DataModal21'), label2: t('DataModal28') },
    { key: "premium", label: t('DataModal22'), label2: t('DataModal29') },
  ];

  const handlePriceChange = (level) => {
    setPriceLevel(level);
  };

  // デフォルトの選択状況に応じたメッセージ表示用
  let defaultMessage = "";
  if (communityid) {
    // URL に communityid がある場合、ユーザーが追加選択しなければ当該サークルのみ選択されているはず
    if (selectedCommunities.length === 1 && selectedCommunities[0] === communityid.toString()) {
      defaultMessage = "今いるサークルが選択されています";
    }
  } else {
    // URL に含まれていない場合、デフォルトは所属中の全サークル
    if (userCommunities.length > 0 && selectedCommunities.length === userCommunities.length) {
      defaultMessage = "所属中のサークルがすべて選択されています";
    }
  }

  return (
    <Dialog
      className="animated"
      open={props.isVisible}
      onClose={props.handleClose}
      aria-labelledby="max-width-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogContent className={`card w-000 shadow-xss rounded-xxl border-0 ps-0 pt-4 pe-0 pb-3 mt-1 mb-1 ${isLoading ? classes.blurredContent : ''}`}>
        <div className="position-absolute right-0 top-5 pointer me-3" onClick={props.handleClose}>
          <i className="ti-close text-grey-400 fw-700 font-xsss"></i>
        </div>
        <div className="card-body ps-2 pe-2 mt-1 pt-0 pb-1 position-relative">
          {profile.img ? (
            <figure className="avatar position-absolute ms-2 mt-2 top---5">
              <img
                src={profile.img.startsWith("http") ? profile.img : process.env.REACT_APP_API_URL + profile.img}
                alt="icon"
                className="shadow-sm rounded-circle w30"
              />
            </figure>
          ) : (
            <figure className="avatar position-absolute ms-2 mt-2 top-5">
              <img
                src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                alt="icon"
                className="shadow-sm rounded-circle w30"
              />
            </figure>
          )}
<textarea
  onChange={(e) => setTitle(e.target.value)}
  value={title}
  name="text"
  className="bor-0 w-100 rounded-xxl p-2 ps-5 font-xss fw-500 theme-dark-text border-light-md theme-dark-bg mb-0"
  cols="30"
  rows="3"
  placeholder={t('DataModal3')}
/>

        </div>
        <div style={{ marginTop: "0.0rem", marginBottom: "0.7rem", textAlign: "center" }}>
          <Button
            variant="contained"
            className={classes.fileInputButton}
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
          >
            <CloudUploadIcon /> ファイルを選択
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        {file && (
          <div style={{ textAlign: "center", marginTop: "0.5rem" }}>
            {file.name}
          </div>
        )}
        <div className={`${classes.line} ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-500 mt-2 mb-2`}>
          詳細設定
        </div>

        {/* データタイプ切替 */}
        <div className="d-flex justify-content-center mt-2 ms-2 me-2">
          <label className="font-xssss fw-500 text-grey-700 me-3">
            <input
              type="checkbox"
              checked={isMyData}
              onChange={() => handleDataTypeChange(true)}
              className="form-check-input"
            />
            <span className="form-check-label ms-1 font-xssss text-grey-600 pointer">
              {t("DataModal30")}
            </span>
          </label>
          <label className="font-xssss fw-500 text-grey-700">
            <input
              type="checkbox"
              checked={!isMyData}
              onChange={() => handleDataTypeChange(false)}
              className="form-check-input"
            />
            <span className="form-check-label ms-1 font-xssss text-grey-600 pointer">
              {t("DataModal31")}
            </span>
          </label>
        </div>

        {/* 投稿先コミュニティ選択欄 */}
        <div className="mt-0 ms-2 me-2">
          <h2 className="font-xsssss text-center mt-3 lh-1 text-grey-500 fw-600">
            投稿先のサークル
          </h2>
          <select
            multiple
            value={selectedCommunities}
            onChange={(e) => {
              const options = e.target.options;
              let values = [];
              for (let i = 0; i < options.length; i++) {
                if (options[i].selected) {
                  values.push(options[i].value);
                }
              }
              setSelectedCommunities(values);
            }}
            style={{ width: "100%", padding: "0.5rem", marginTop: "0rem" }}
            className="font-xssss fw-500 text-grey-500 h50 bor-0 w-100 border-light-md theme-dark-bg mb-0"
          >
            {userCommunities.map((comm) => (
              <option key={comm.id} value={comm.id}>
                {comm.title}
              </option>
            ))}
          </select>
          {defaultMessage && (
            <div className="text-center  font-xssssss text-grey-500 fw-500">
              {defaultMessage}
            </div>
          )}
        </div>

        <div className="price-selection row justify-content-center ms-1 me-1 mb-5">
          <h2 className="font-xsssss text-center mt-3 lh-1 text-grey-500 fw-600">
            {isMyData ? t('DataModal23') : t('DataModal33')}
          </h2>
          <div className="price-buttons d-flex justify-content-center flex-wrap">
            {priceLevels.map((level) => (
              <Button
                key={level.key}
                onClick={() => handlePriceChange(level.key)}
                className={`border shadow-xss card-body ps-1 pe-1 pt-0 pb-0 text-center ms-1 mb-1 me-1 ${priceLevel === level.key ? "bg-current text-white" : (isMyData ? "text-grey-600" : "text-grey-500")}`}
                disabled={!isMyData}
              >
                <h4 className={`fw-400 font-xssss mt-1 mb-0 ${priceLevel === level.key ? "text-white" : (isMyData ? "text-grey-600" : "text-grey-500")}`}>
                  {level.label}
                  <br />
                  <h4 className={`fw-400 font-xssssss mt-0 mb-1 ${priceLevel === level.key ? "text-white" : (isMyData ? "text-grey-600" : "text-grey-500")}`}>
                    {level.label2}
                  </h4>
                </h4>
              </Button>
            ))}
          </div>
        </div>

        <div className="card-body p-0 d-flex">
          <div className="file-upload-container position-absolute right-15 bottom-5">
            {title && file ? (
              <Button onClick={handleSubmit}
                className="bg-current text-center text-white font-xsss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1"
                variant="contained"
              >
                {t('DataModal11')}
              </Button>
            ) : (
              <Button
                className="text-center text-white font-xsss fw-600 pt-1 pb-1 ps-1 pe-1 ms-2 w125 rounded-3 mt-1"
                variant="contained"
                disabled
              >
                {t('DataModal12')}
              </Button>
            )}
          </div>
        </div>
        {isLoading && (
          <span className={classes.spinner}></span>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DataModal;
